import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { themes } from '../../styles/theme';

class AngelList extends Component {

  static propTypes = {
    title: PropTypes.string,
    iconStyle: PropTypes.object
  }

  static defaultProps = {
    title: 'AngelList',
    iconStyle: {}
  }

  render() {

    const primaryColor = this.props.iconStyle.primaryColor || themes.primary.primaryColor;
    const commonStyle = {
    };

    const Path = styled.path(commonStyle,
      props => ({
        ...props.styles
      })
    );

    const SVG = styled.svg({},
      props => ({
        ...props.styles
      })
    );

    return (
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        className={this.props.className}
        aria-labelledby="title"
     	  viewBox="0 0 200.24 287.53"
        styles={this.props.styles}
      >
        <title>{this.props.title}</title>
        <Path d="M373.74,343.77q17.76,3.36,25.08,15t7.3,36.73q0,49.76-29.92,81.46t-76.52,31.69
    A98.87,98.87,0,0,1,264,502.05a88.3,88.3,0,0,1-29.73-18.47q-14.2-13-21.32-27.54a66.9,66.9,0,0,1-7.11-29.73q0-17,7.3-26.26
    t23.11-12.64a105,105,0,0,1-4.35-11.65,28.42,28.42,0,0,1-1.38-7.31q0-8.88,9.38-18.16T257.62,341
    a24.14,24.14,0,0,1,7.5,1.29,50,50,0,0,1,9.09,4.24q-15.21-43-22.12-66.45t-6.91-32.29q0-12.24,6.32-19.45
    t17.18-7.21q18.56,0,47,82.94,4.94,14.22,7.5,21.93,2.18-6.12,6.12-17.38,28.44-82.16,49-82.16,10.07,0,16.09,6.82
    t6,18.27q0,8.68-6.62,31.79T373.74,343.77Zm-141.4,80.57q2.76,3.36,7.7,9.87,13,18,24.09,18a10.49,10.49,0,0,0,6.72-2.37
    c2-1.57,3-3.22,3-4.93q0-3-3.95-9.88A128.61,128.61,0,0,0,259,419.4a118.1,118.1,0,0,0-13.13-14.71q-5.24-4.63-8.4-4.64-6.91,0-12.73,7.41
    a27.37,27.37,0,0,0-5.83,17.47q0,8.1,4.05,18.07a87.22,87.22,0,0,0,11.75,20.05,78.47,78.47,0,0,0,28.93,24,86.23,86.23,0,0,0,38.21,8.39
    q38.52,0,64.48-28.73t26-72q0-13.23-2-21t-6.51-11.36q-8.1-6.7-31.5-12A219.06,219.06,0,0,0,303.43,345q-7.11,0-10.07,2.37t-3,8.1
    q0,13.43,15,19.45t48.58,6h8.09a5.45,5.45,0,0,1,4.45,2.08,12.82,12.82,0,0,1,2.27,6.22q-3.36,3.17-13.83,7.21t-16,8
    A71.12,71.12,0,0,0,319.92,425q-7.21,12-7.21,22.61,0,6.53,3.07,15.7t3.06,11.36v1.38l-.4,1.78q-8.69-.6-13.72-10.17
    t-5-25.58v-1.77a10.85,10.85,0,0,1-3.06,2,8.6,8.6,0,0,1-3.26.59,19,19,0,0,1-3.36-.29c-1.05-.2-2.24-.5-3.55-.89
    a36.49,36.49,0,0,1,.89,4.24,24,24,0,0,1,.29,3.26,16.38,16.38,0,0,1-5.72,12.54A19.54,19.54,0,0,1,268.28,467q-12.43,0-25.28-12.15
    t-12.83-23.8a13.68,13.68,0,0,1,.49-3.85A6.67,6.67,0,0,1,232.34,424.34Zm59.24,4.74a8.17,8.17,0,0,0,6.13-3.06,9.72,9.72,0,0,0,2.76-6.62
    q0-3.75-5.43-17.47a174.72,174.72,0,0,0-13.53-27q-5.92-9.87-11.65-14.91T259,355q-4.15,0-9.18,5.24t-5,9.77q0,4.74,4.94,14.22
    A133.86,133.86,0,0,0,263,404.39a109.85,109.85,0,0,0,16.39,18.17Q287,429.09,291.58,429.08Zm19.36-95.38-21.33-61.62
    q-8.3-23.49-12.84-30.71t-9.87-7.2a8.23,8.23,0,0,0-6.72,3.16q-2.56,3.15-2.56,8.49,0,9.08,6.91,31.59t20.73,59
    a6.57,6.57,0,0,1,3.36-3.06,16.09,16.09,0,0,1,5.93-.89c.79,0,2.37.07,4.74.2S305.54,333.17,310.94,333.7Zm22.31,59.83
    a121.09,121.09,0,0,1-16.09-1.77,85.63,85.63,0,0,1-14.52-3.95q3.17,6.31,5.63,12.64
    a109.62,109.62,0,0,1,4.05,12.44,98.29,98.29,0,0,1,10-10.77A84.05,84.05,0,0,1,333.25,393.53Zm26.27-53.12
    q13.22-35.94,20.63-59.34t7.41-28.74c0-3.81-.82-6.78-2.47-8.88a8.17,8.17,0,0,0-6.81-3.16
    q-5.53,0-11.26,9.18T354.38,279l-19.75,57.08Z"
          transform="translate(-205.88 -221.13)"
          styles={{fill: primaryColor, ...this.props.iconStyle}}
        />
     </SVG>
   )
  }
}

export default AngelList;
