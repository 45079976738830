import React, { Component } from 'react';
import { Section, Slide, Content, Division, Heading } from '../elements/core';
//import CompanySlide from './elements/CompanySlide';
import { FloatingImage } from '../elements/common';
import { commonStyles } from '../../styles/common';
import { getStyledFloatingImages } from './utils/floatingImages';

class FounderStoriesSection extends Component {

  state = {
    featureImages: []
  };

  componentDidMount() {
    this.setState({
      featureImages: getStyledFloatingImages(this.props.founderStories.featureImages)
    });
  }

  render() {
    const { sectionCopy = {} } = this.props; 
    return (
      <Section styles={{...commonStyles.section, ...customStyles.section}} data-role="founder-stories">
        <Slide styles={customStyles.slide}  >
          <Content styles={customStyles.content}>
            <Division styles={commonStyles.sectionTitle}>
              <Division styles={commonStyles.tagline}>{sectionCopy.tagline}</Division>
              <Heading styles={commonStyles.sectionHeading}>{sectionCopy.heading}</Heading>
            </Division>
            <Division styles={{...commonStyles.description, ...customStyles.description}}>
              <Heading as="h5">{sectionCopy.description}</Heading>
            </Division>
          </Content>
          {
            this.props.rendersFullPage ?
              <Division styles={customStyles.floatingImages.group}>
              {
                this.state.featureImages.map((image, index) => (
                  <FloatingImage key={index} imageData={image} styles={customStyles.floatingImages.image}></FloatingImage>
                ))
              }
              </Division>
            : null
          }
        </Slide>
        {
          this.props.founderStories.stories.map((story, index) => (
            null
          ))
        }
      </Section>
    );
  }
}

const customStyles = {
  section: {
    background: '#13354e'
  },
  content: {
    color: '#a5c8d1',
    transform: `scale(${window.innerHeight > 800 ? 1 : (window.innerHeight/800)})`
  },
  description: {
    '@media (min-width: 576px)': {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
  },
  floatingImages: {
    group: {
      ...commonStyles.fullWidthAbsolute,
      ...commonStyles.marginsCenter,
      width: '100%',
      zIndex: -1
    },
    image: {
      container: {
        width: '8rem',
        height: '8rem',
        borderRadius: '50%',
        border: '0.2rem solid #a5c8d1'
      },
      overlay: {
        opacity: 0.4,
        background: '#13354e',
        transition: 'opacity .3s cubic-bezier(.4, 0, 0, 1)',
        borderRadius: '100%'
      },
      link: {

      },
      name: {

      }
    }
  }
};

export default FounderStoriesSection;
