export const fundMetrics = [
  {
    title: 'Total Investments',
    number: '45',
    description: "Checks we've written across US & India"
  },
  {
    title: 'Total Raised',
    number: '$190M',
    description: 'Total capital raised by our companies across multiple rounds'
  },
  {
    title: 'Follow On Success',
    number: '70%',
    description: "Our companies' success rate at closing a follow on round within 18 months"
  },
  {
    title: 'Total Valuation',
    number: '$650M',
    description: 'Cumulative valuation of all our companies'
  },
  {
    title: 'Notable Exits',
    number: '1',
    description: "Investments we've had a successful exit from"
  }
];