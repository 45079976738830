import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { themes } from '../../styles/theme';

class Playbook extends Component {

  static propTypes = {
    title: PropTypes.string,
    iconStyle: PropTypes.object
  }

  static defaultProps = {
    title: 'Playbook',
    iconStyle: {}
  }

  render() {

    const primaryColor = this.props.iconStyle.primaryColor
                          || themes.primary.primaryColor;

    const secondaryColor = this.props.iconStyle.secondaryColor
                          || themes.primary.primaryLightColor;


    const commonStyle = {
      strokeWidth: 0.5,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
      fill: 'none'
    };

    const Path = styled.path(commonStyle,
      props => ({
        ...props.styles
      })
    );

    const Line = styled.line(commonStyle,
      props => ({
        ...props.styles
      })
    );

    const Circle = styled.circle(commonStyle,
      props => ({
        ...props.styles
      })
    );

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={this.props.className}
        aria-labelledby="title"
     	  viewBox="0 0 333.1 47.3"
        style={this.props.styles}
      >
        <title>{this.props.title}</title>
        <g name="left-play">
        	<Circle cx="2.1" cy="3" r="1.8"
            styles={{stroke: primaryColor, ...this.props.iconStyle.leftPlayCircle}}
          />
        	<Path d="M6,2.8c0,0,42.3-1,64,38"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.leftPlayLine}}
          />
        	<Circle cx="72.1" cy="44" r="1.8"
            styles={{stroke: primaryColor, ...this.props.iconStyle.leftPlayCircle}}
          />
        </g>
        <g name="center-play">
        	<Circle cx="168" cy="3" r="1.8"
            styles={{stroke: primaryColor, ...this.props.iconStyle.centerPlayCircle}}
          />
        	<Line x1="168" y1="6.7" x2="168" y2="40.3"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.centerPlayLine}}
          />
          <Circle cx="168" cy="44" r="1.8"
            styles={{stroke: primaryColor, ...this.props.iconStyle.centerPlayCircle}}
          />
        </g>
        <g name="right-play">
          <Circle cx="331.1" cy="3" r="1.8"
            styles={{stroke: primaryColor, ...this.props.iconStyle.rightPlayCircle}}
          />
          <Path d="M327.3,2.8c0,0-42.3-1-64,38"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.rightPlayLine}}
          />
          <Circle cx="261.1" cy="44" r="1.8"
            styles={{stroke: primaryColor, ...this.props.iconStyle.rightPlayCircle}}
          />
        </g>
     </svg>
   )
  }
}

export default Playbook;
