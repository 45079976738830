
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { themes } from '../../styles/theme';

class Stage extends Component {

  static propTypes = {
    title: PropTypes.string,
    iconStyle: PropTypes.object
  }

  static defaultProps = {
    title: 'Stage',
    iconStyle: {}
  }

  render() {

    const primaryColor = this.props.iconStyle.primaryColor
                          || themes.primary.primaryColor;
    const secondaryColor = this.props.iconStyle.secondaryColor
                          || themes.primary.primaryLightColor;

    const commonStyle = {
      strokeWidth: 3,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
      fill: 'none',
    };

    const Path = styled.path(commonStyle, props => ({...props.styles}));
    const Polyline = styled.polyline(commonStyle, props => ({...props.styles}));
    const Circle = styled.circle(commonStyle, props => ({...props.styles}));
    const Ellipse = styled.ellipse(commonStyle, props => ({...props.styles}));
    const SVG = styled.svg(commonStyle, props => ({...props.styles}));

    return (
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        className={this.props.className}
        aria-labelledby="title"
     	  viewBox="0 0 120 120"
        styles={this.props.styles}
      >
        <title>{this.props.title}</title>
        <Circle opacity=".5" cx="82.2" cy="92.3" r="27.2"
          styles={{fill: secondaryColor, opacity: 0.1}}        
        />
        <Circle cx="72.2" cy="83.8" r="27.2"
          styles={{fill: primaryColor}}                
        />
        <g>
          <Path d="M76.4,82.9c1.3,0.4,2.3,1.1,3.2,2c0.9,1,1.5,2.1,1.8,3.3s0.2,2.6-0.2,3.9c-0.5,1.4-1.4,2.6-2.7,3.4
            c-1.3,0.8-2.7,1.3-4.2,1.3v3.1c0,0.3-0.1,0.6-0.3,0.7s-0.4,0.3-0.7,0.3h-2.1c-0.3,0-0.6-0.1-0.7-0.3s-0.3-0.4-0.3-0.7v-3.1
            c-2.1,0-4-0.6-5.6-1.9c-0.2-0.2-0.3-0.5-0.4-0.8c0-0.3,0.1-0.6,0.3-0.8l2.2-2.2c0.2-0.1,0.4-0.2,0.6-0.3c0.2,0,0.5,0,0.7,0.2
            c0.6,0.4,1.4,0.6,2.2,0.6h4.3c0.5,0,1-0.2,1.3-0.6c0.4-0.4,0.6-0.8,0.6-1.4c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.6-0.6-1-0.7L68.3,86
            c-1.5-0.4-2.7-1.2-3.7-2.3c-1-1.1-1.6-2.4-1.8-3.9c-0.1-1.4,0.2-2.7,0.8-3.9s1.5-2.1,2.6-2.9c1.1-0.7,2.4-1.1,3.8-1.1h0.1v-3.1
            c0-0.3,0.1-0.6,0.3-0.7s0.4-0.3,0.7-0.3h2.1c0.3,0,0.6,0.1,0.7,0.3s0.3,0.4,0.3,0.7v3.1c2.1,0,3.9,0.6,5.6,1.9
            c0.3,0.2,0.4,0.5,0.5,0.8c0,0.3-0.1,0.6-0.3,0.8l-2.2,2.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0-0.5,0-0.7-0.1c-0.6-0.5-1.4-0.7-2.2-0.7
            h-4.3c-0.5,0-1,0.2-1.3,0.6c-0.4,0.4-0.6,0.8-0.6,1.4c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.6,1,0.7L76.4,82.9z"
            styles={{fill: secondaryColor}}                    
          />
        </g>
        <Path d="M38.4,81.7H7.1c-2.7,0-5-2.2-5-5V7.5c0-2.7,2.2-5,5-5h106c2.7,0,5,2.2,5,5v69.2c0,2.7-2.2,5-5,5l0,0"
          styles={{stroke: secondaryColor}}                            
        />
        <Ellipse cx="35.3" cy="12.6" rx="3.1" ry="3.1"
          styles={{stroke: secondaryColor, opacity: 0.5}}        
        />
        <Circle cx="23.6" cy="12.6" r="3.1"
          styles={{stroke: secondaryColor, opacity: 0.5}}                
        />
        <Circle cx="12" cy="12.6" r="3.1"
          styles={{stroke: primaryColor}}                        
        />
        <Polyline points="14.7,63.8 43.3,48.1 48.6,53.9 71.2,40.1 78.5,48.5 110.4,21.6 "
          styles={{stroke: primaryColor}}                                
        />
        <Polyline points="101.3,23.6 110.4,21.6 107.4,30.2 "
          styles={{stroke: primaryColor}}                                        
        />
        <Polyline points="83.5,34.8 83.5,17.3 89.3,17.3 89.3,29.6 "
           styles={{stroke: secondaryColor, opacity: 0.5}}                       
        />
        <Polyline points="60.8,39 60.8,23.6 66.5,23.6 66.5,34.8 "
          styles={{stroke: secondaryColor, opacity: 0.5}}                        
        />
        <Polyline points="49.4,45.4 49.4,36.3 55.1,36.3 55.1,42.2 "
          styles={{stroke: secondaryColor, opacity: 0.5}}                        
        />
        <Polyline points="38,43.3 38,32 43.7,32 43.7,40.1 "
          styles={{stroke: secondaryColor, opacity: 0.5}}                        
        />
        <Polyline points="26.6,49.6 26.6,41.6 32.3,41.6 32.3,46.4 "
          styles={{stroke: secondaryColor, opacity: 0.5}}                        
        />
        <Polyline points="72.2,33.6 72.2,27.8 77.9,27.8 77.9,37.9 "
          styles={{stroke: secondaryColor, opacity: 0.5}}                
        />
     </SVG>
   )
  }
}

export default Stage;