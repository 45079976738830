import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ReactGA from 'react-ga';

import * as urlConstants from '../constants/urls';
import * as configConstants from '../constants/config';


import LandingContainer from './landing/Container';

const httpLink = createHttpLink({
  uri: urlConstants.GRAPHQL_ENDPOINT
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

ReactGA.initialize(configConstants.GA_TRACKING_ID, {
});

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ApolloProvider client={client}>
          <Switch>
            <Route exact path="/" component={LandingContainer} />
          </Switch>
        </ApolloProvider>
      </BrowserRouter>
    );
  }
}

export default App;
