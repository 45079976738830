import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { themes } from '../../styles/theme';

class Thesis extends Component {

  static propTypes = {
    title: PropTypes.string,
    iconStyle: PropTypes.object
  }

  static defaultProps = {
    title: 'Thesis',
    iconStyle: {}
  }

  render() {

    const primaryColor = this.props.iconStyle.primaryColor
                          || themes.primary.primaryColor;
    const secondaryColor = this.props.iconStyle.secondaryColor
                          || themes.primary.primaryLightColor;

    const commonStyle = {
      strokeWidth: 3,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
      fill: 'none',
    };

    const Path = styled.path(commonStyle, props => ({...props.styles}));
    const SVG = styled.svg(commonStyle, props => ({...props.styles}));

    return (
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        className={this.props.className}
        aria-labelledby="title"
     	  viewBox="0 0 120 120"
        styles={this.props.styles}
      >
        <title>{this.props.title}</title>
        <g>
          <Path d="M52.9,42.5c-2.7,0-5.2,1.2-6.8,3.2v-17h-36c-5,0-9.1,4.1-9.1,9.1v36h16.7c-1.8-1.6-3-4-3-6.6
      c0-4.9,3.9-8.8,8.8-8.8c4.9,0,8.8,3.9,8.8,8.8c0,2.6-1.1,5-3,6.6h16.7V56.8c1.6,2,4,3.2,6.8,3.2c4.8,0,8.7-3.9,8.7-8.7
      C61.7,46.4,57.8,42.5,52.9,42.5z"
            styles={{stroke: secondaryColor}}            
          />
          <Path d="M17.8,73.8H1v36c0,5,4.1,9.1,9.1,9.1h36v-16.7c-1.6,1.8-4,3-6.6,3c-4.9,0-8.8-3.9-8.8-8.8s3.9-8.8,8.8-8.8
            c2.6,0,5,1.1,6.6,3V73.8H29.5"
            styles={{stroke: secondaryColor}}                        
          />
          <Path d="M46.2,102.3V119h36c5,0,9.1-4.1,9.1-9.1v-36H74.6c1.8,1.6,3,4,3,6.6c0,4.9-3.9,8.8-8.8,8.8s-8.8-3.9-8.8-8.8
            c0-2.6,1.1-5,3-6.6H46.2v16.7"
            styles={{stroke: secondaryColor}}                        
          />
          <Path d="M105.9,16.1c-1.4-4.8-6.5-7.6-11.3-6.2L60,19.8l4.6,16.1c1-2.2,3-3.9,5.5-4.7c4.7-1.4,9.6,1.3,10.9,6
            c1.4,4.7-1.3,9.6-6,10.9c-2.5,0.7-5.1,0.3-7.1-1l4.6,16.1l15.7-4.5c-1.1,2-1.5,4.4-0.8,6.8c1.4,4.7,6.3,7.5,11.1,6.1
            c4.7-1.4,7.5-6.3,6.1-11.1c-0.7-2.4-2.3-4.3-4.3-5.4l15.6-4.5L105.9,16.1z"
            styles={{fill: secondaryColor, opacity: 0.1}}                        
          />
          <Path d="M110,6.6c-1.4-4.8-6.5-7.6-11.3-6.2l-34.6,10l4.6,16.1c1-2.2,3-3.9,5.5-4.7c4.7-1.4,9.6,1.3,10.9,6
            c1.4,4.7-1.3,9.6-6,10.9c-2.5,0.7-5.1,0.3-7.1-1l4.6,16.1l15.7-4.5c-1.1,2-1.5,4.4-0.8,6.8c1.4,4.7,6.3,7.5,11.1,6.1
            c4.7-1.4,7.5-6.3,6.1-11.1c-0.7-2.4-2.3-4.3-4.3-5.4l15.6-4.5L110,6.6z"
            styles={{fill: primaryColor}}                                    
          />
        </g>
     </SVG>
   )
  }
}

export default Thesis;
