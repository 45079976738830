import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

class Logo extends Component {

  static propTypes = {
    title: PropTypes.string,
    styles: PropTypes.object.isRequired
  }

  static defaultProps = {
    title: 'Globevestor',
    styles: {
      color: 'white'
    }
  }

  render() {

    const FillPath = styled.path({
        fill: this.props.styles.color
      },
      props => ({
        ...props.overrideStyle
      })
    );

    const StrokePath = styled.path({
        fill: 'none',
        stroke: this.props.styles.color,
        strokeWidth: 12
      },
      props => ({
        ...props.overrideStyle
      })
    );


    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={this.props.className}
        aria-labelledby="title"
     	  viewBox="0 0 2674.9 296.2"
        style={this.props.styles}
        >

        <title>{this.props.title}</title>
        <FillPath d="M20.5,46.7h57.7c41.2,0,69.5,6.5,84.8,19.6c15.3,13.1,23,34.8,23,65.1c0,33.6-7.9,59-23.7,76.2
          c-15.8,17.1-42.6,25.7-80.5,25.7l-66.9,0.1c-1.6-4-2.5-7.9-2.5-11.6c0-2.5,0.7-4.3,2.1-5.4c1.4-1.1,3.4-1.7,6-1.7h10
          c0.5-11.8,0.8-42.3,0.8-91.7c0-26.7-0.3-45.9-0.8-57.6H14.9c-1.6-4-2.5-7.8-2.5-11.5c0-2.6,0.7-4.4,2.1-5.5
          C15.9,47.3,17.9,46.7,20.5,46.7z M91.3,72.9c-6.6,0-12.3,0.5-17.1,1.4c-0.5,12.8-0.7,42.3-0.7,88.7c0,21.6,1.3,34.3,4,38.2
          c2.6,3.9,9.5,5.8,20.6,5.8c13.2,0,23.3-4.3,30.2-13c6.9-8.7,10.4-26.1,10.4-52.2c0-25.8-4.1-43.7-12.2-53.8
          C118.4,78,106.6,72.9,91.3,72.9z"/>
        <FillPath d="M272.2,236c-20.4,0-35.8-5.3-46.1-16c-10.3-10.7-15.5-26.1-15.5-46.3c0-25.2,6.3-44.8,18.8-58.7
          c12.5-13.9,30.1-20.8,52.8-20.8c20.4,0,35.8,5.3,46.1,16c10.3,10.7,15.4,26.1,15.4,46.3c0,25.2-6.2,44.8-18.7,58.7
          C312.5,229.1,294.9,236,272.2,236z M278.6,212.4c7.4,0,12.9-2.9,16.5-8.8c3.6-5.9,5.4-17.3,5.4-34.2c0-18.5-2-31.7-5.9-39.6
          s-10.2-11.9-18.8-11.9c-7.4,0-12.9,2.9-16.5,8.8c-3.6,5.9-5.4,17.3-5.4,34.2c0,18.5,2,31.7,5.9,39.6S270.1,212.4,278.6,212.4z"/>
        <FillPath d="M491.1,172.9c0,18.1,0.7,29.4,2,33.7c1.3,4.3,4.7,6.3,10,5.8c1.3,3,1.9,5.9,1.9,8.6c0,4.5-3.3,7.9-10,10.2
          c-6.7,2.3-12.8,3.5-18.3,3.5c-4.3,0-7.3-0.7-9-2c-1.7-1.3-4.4-4.6-8.1-9.9c-8.5,4.3-16.6,7.6-24.4,9.8s-16.1,3.4-25,3.4
          c-11.7,0-20.8-3.1-27.5-9.2c-6.7-6.2-10-18.7-10-37.5c0-28.2-0.2-45.5-0.5-52.2c-0.3-6.6-1-11.4-2.1-14.5c-1.1-3.1-2.6-5-4.4-5.7
          c-1.8-0.8-4.5-1.2-7.9-1.2c-1.6-2.6-2.5-5.5-2.5-8.9c0-4.2,2.6-7.1,7.7-8.8c5.1-1.6,13.2-2.5,24.2-2.5c7.9,0,13.5,0.5,16.8,1.4
          s5.3,2.4,6.1,4.3c0.8,1.9,1.2,8.3,1.2,19.3v48.7c0,17.8,1.7,29.4,5.1,34.9c3.4,5.5,8.2,8.2,14.4,8.2c4.6,0,8.9-1,12.9-3.1
          c3.9-2.1,6.4-4.7,7.4-8.1c1-3.3,1.5-9.3,1.5-18c0-24.2,0-39.5-0.1-46.1c-0.1-6.6-0.6-11.4-1.5-14.5c-0.9-3.1-2.1-5-3.5-5.8
          c-1.4-0.8-3.9-1.2-7.3-1.2c-1.6-2.6-2.5-5.5-2.5-8.9c0-4.2,2.6-7.1,7.7-8.8c5.1-1.6,12.4-2.5,21.7-2.5c7.9,0,13.5,0.5,16.8,1.4
          s5.3,2.6,6.1,4.9c0.8,2.3,1.2,9,1.2,20V172.9z"/>
        <FillPath d="M536.2,167.4c0-41.4-0.2-67.4-0.5-77.9c-0.3-10.6-1-17.8-2.1-21.7c-1-3.9-2.8-6.4-5.3-7.5
          c-2.5-1.1-5.8-1.7-9.8-1.7c-1.6-3.7-2.3-7.5-2.3-11.2c0-3.8,1.4-6.3,4.2-7.3c2.8-1,12-1.6,27.6-1.6c9.7,0,16.2,0.4,19.8,1.2
          c3.5,0.8,5.5,2.2,6.1,4.3c0.5,2.1,0.8,5.1,0.8,8.9l-2.5,50.7c5.6-2.7,11.9-5,18.9-6.8c7.1-1.8,14.6-2.7,22.6-2.7
          c14.9,0,26.9,4.6,35.8,13.9s13.3,25.2,13.3,47.9c0,26.6-6.3,46.6-18.8,60c-12.5,13.4-29.5,20.1-50.9,20.1c-7.5,0-14.2-0.6-20.2-1.8
          c-6-1.2-10.6-2.6-13.9-4l-5.9,4.4c-8,0-13.1-0.6-15.2-1.9c-2.1-1.3-3.1-3.9-3.1-7.9c0-3.7,0.2-12.2,0.6-25.5
          S536.2,175.3,536.2,167.4z M574.6,158.8l0.1,46.3c2.4,2.3,5.5,4.1,9.3,5.3c3.8,1.3,7.8,1.9,12,1.9c7.7,0,13.5-2.6,17.6-7.7
          c4.1-5.1,6.1-17.6,6.1-37.5c-0.1-18.1-2-30.9-5.7-38.3c-3.7-7.4-9.4-11.1-17-11.1c-4.8,0-8.9,1.1-12.2,3.2
          c-3.3,2.1-5.8,5.6-7.5,10.3C575.6,136.1,574.7,145.2,574.6,158.8z"/>
        <FillPath d="M698.5,167.4c0-41.4-0.2-67.4-0.5-77.9c-0.4-10.6-1-17.8-2.1-21.7c-1-3.9-2.8-6.4-5.3-7.5
          c-2.5-1.1-5.8-1.7-9.8-1.7c-1.6-3.7-2.5-7.5-2.5-11.2c0-3.8,1.4-6.3,4.3-7.3c2.9-1,12.1-1.6,27.5-1.6c9.7,0,16.3,0.4,19.8,1.2
          c3.6,0.8,5.6,2.2,6.1,4.3c0.5,2.1,0.8,5.1,0.8,8.9V158c0,26.1,0.2,44.9,0.7,56.6h17.5c1.5,3,2.3,6.4,2.3,10.1c0,3.2-0.7,5.4-2,6.7
          c-1.3,1.3-3.9,1.9-7.7,1.9h-66.9c-1.6-3.8-2.5-7.4-2.5-10.8c0-2.8,0.6-4.9,1.9-6.1c1.3-1.2,3.3-1.8,6.2-1.8h11.2
          C698.2,202.9,698.5,187.2,698.5,167.4z"/>
        <FillPath d="M840.8,94.1c19,0,32.7,4.3,41.3,12.9c8.6,8.6,12.9,22.2,12.9,40.8c0,9.2-1.8,15.8-5.5,19.7
          c-3.6,3.9-11.6,5.9-23.9,5.9h-50.9c0.3,11.9,3,21.3,8.1,28.1c5.1,6.8,14.1,10.2,26.9,10.2c12.6,0,25.5-3.6,38.8-10.9
          c4.7,4.6,7.1,9.7,7.1,15c0,7.3-5.4,12.4-16.1,15.2c-10.7,2.9-23.3,4.3-37.7,4.3c-24.8,0-42.8-5-54-15c-11.2-10-16.8-26.6-16.8-49.6
          c0-24,6.3-42.7,18.9-56.3C802.7,100.9,819.6,94.1,840.8,94.1z M835.4,117.8c-6.7,0-11.9,2.7-15.4,8.2s-5.4,15-5.5,28.6h7.2
          c10.1,0,17.8-0.3,23.2-0.9c5.3-0.6,8.5-1.6,9.4-3c0.9-1.4,1.4-3.8,1.4-7c0-8.2-1.8-14.6-5.3-19.1S841.7,117.8,835.4,117.8z"/>
        <FillPath d="M926.8,46.7h57.7c41.2,0,69.5,6.5,84.8,19.6c15.3,13.1,23,34.8,23,65.1c0,33.6-7.9,59-23.7,76.2
          c-15.8,17.1-42.6,25.7-80.5,25.7l-66.9,0.1c-1.6-4-2.5-7.9-2.5-11.6c0-2.5,0.7-4.3,2.1-5.4c1.4-1.1,3.4-1.7,6-1.7h10
          c0.5-11.8,0.8-42.3,0.8-91.7c0-26.7-0.3-45.9-0.8-57.6h-15.6c-1.6-4-2.5-7.8-2.5-11.5c0-2.6,0.7-4.4,2.1-5.5
          C922.2,47.3,924.2,46.7,926.8,46.7z M997.6,72.9c-6.6,0-12.3,0.5-17.1,1.4c-0.5,12.8-0.7,42.3-0.7,88.7c0,21.6,1.3,34.3,4,38.2
          c2.6,3.9,9.5,5.8,20.6,5.8c13.2,0,23.3-4.3,30.2-13c6.9-8.7,10.4-26.1,10.4-52.2c0-25.8-4.1-43.7-12.2-53.8
          C1024.7,78,1012.9,72.9,997.6,72.9z"/>
        <FillPath d="M1178.5,236c-20.4,0-35.8-5.3-46.1-16c-10.3-10.7-15.5-26.1-15.5-46.3c0-25.2,6.3-44.8,18.8-58.7
          c12.5-13.9,30.1-20.8,52.8-20.8c20.4,0,35.8,5.3,46.1,16c10.3,10.7,15.4,26.1,15.4,46.3c0,25.2-6.2,44.8-18.7,58.7
          C1218.8,229.1,1201.2,236,1178.5,236z M1184.9,212.4c7.4,0,12.9-2.9,16.5-8.8c3.6-5.9,5.4-17.3,5.4-34.2c0-18.5-2-31.7-5.9-39.6
          s-10.2-11.9-18.8-11.9c-7.4,0-12.9,2.9-16.5,8.8c-3.6,5.9-5.4,17.3-5.4,34.2c0,18.5,2,31.7,5.9,39.6S1176.4,212.4,1184.9,212.4z"/>
        <FillPath d="M1481.6,118.3c-2.5,6.8-8.7,25.9-18.8,57.3c-10.1,31.4-17.1,49.3-21.1,53.7s-9.8,6.7-17.6,6.7
          c-13.5,0-20.8-0.7-21.8-2.2c-1-1.5-4.9-18.4-11.6-50.8c-6.7-32.4-10.6-48.6-11.8-48.6s-5.1,14.7-11.6,44.1
          c-6.5,29.4-11.7,46.3-15.7,50.8s-9.4,6.7-16.3,6.7c-17.7,0-27.1-0.7-28.2-2.2s-4.6-16.2-10.7-44.1c-6-27.9-10-45.3-12-52.1
          c-2-6.8-3.7-11.8-5.2-14.9c-1.5-3.1-3.1-5.1-4.9-5.9c-1.8-0.8-4.9-1.2-9.2-1.2c-1.6-3.4-2.5-7-2.5-10.8c0-4.2,1.6-6.8,4.9-7.8
          c3.3-1,12.5-1.5,27.8-1.5c7.3,0,12.5,0.5,15.7,1.6c3.2,1.1,5.4,3.1,6.6,5.9s2.7,9.6,4.4,20.2c1.7,10.6,4.4,26.9,8,48.9
          c3.6,22.1,6.1,33.1,7.2,33.1c1.2,0,5-14.6,11.6-43.7c6.5-29.1,11.1-50.7,13.9-64.9c22.6-0.8,35.5-0.7,38.8,0.3c3.2,1,5.5,3,6.7,5.9
          s2.7,9.4,4.3,19.5s4.8,26.5,9.4,49c4.6,22.6,7.5,33.8,8.8,33.8s4.7-12.5,10.5-37.5c5.7-25,9-41.5,9.8-49.6h-8.6
          c-1.6-3.2-2.5-6.6-2.5-10.1s0.7-6.1,2.1-7.7c1.4-1.6,3.7-2.4,6.9-2.4h41.4c1.5,3.1,2.3,6.4,2.3,9.8c0,4-0.8,6.7-2.3,8.2
          c-1.6,1.5-4.2,2.2-8.1,2.2H1481.6z"/>
        <FillPath d="M1558.3,111.4c9.9-6.2,19.5-10.6,28.6-13.2s16.8-3.9,23-3.9c12.9,0,22.4,2.8,28.6,8.5c6.2,5.7,9.4,17,9.4,34.2
          c0,44.8,0.8,69,2.5,72.5c1.6,3.5,5.2,5.2,10.7,5.1c1.4,3.3,2.1,6.7,2.1,10.3c0,3.3-1.1,5.5-3.2,6.7c-2.1,1.2-6.2,1.8-12.2,1.8
          c-14.8,0-24.1-0.2-28-0.7c-3.9-0.5-6.6-1.9-8.1-4.4c-1.5-2.5-2.2-6.7-2.2-12.6v-48.3c0-18.6-1.7-31.1-5.1-37.4
          c-3.4-6.3-9-9.5-16.6-9.5c-6.5,0-11.6,1.9-15.3,5.7c-3.7,3.8-5.6,11.7-5.6,23.7v27.5c0,11.5,0.2,23.9,0.7,37.3h16.3
          c1.5,3,2.3,6.4,2.3,10.1c0,3.2-0.7,5.4-2,6.7c-1.3,1.3-3.9,1.9-7.7,1.9H1512c-1.6-3.8-2.5-7.4-2.5-10.8c0-2.8,0.6-4.9,1.9-6.1
          c1.3-1.2,3.3-1.8,6.2-1.8h10c0.5-15,0.7-30.6,0.7-46.6c0-25.5-1.2-40.6-3.6-45.3c-2.4-4.6-6.6-7-12.6-7c-1.6-3.6-2.5-7.3-2.5-10.9
          c0-3.3,1.2-5.4,3.6-6.4c2.4-1,17.5-1.9,45.2-2.7C1558.8,99.5,1558.8,104.7,1558.3,111.4z"/>
        <FillPath d="M1850.2,209.8c15.5,0,29.8-4.2,42.8-12.6c6.1,6.7,9.2,13.4,9.2,19.8c0,6.2-6.4,10.9-19.2,14.2
          c-12.8,3.2-27.8,4.9-44.9,4.9c-27.7,0-48.3-6.1-61.8-18.2c-13.5-12.1-20.2-34.8-20.2-67.9c0-36.4,8.1-63.1,24.2-80.3
          c16.1-17.1,39-25.7,68.6-25.7c19.4,0,32.9,1.4,40.3,4.2c7.5,2.8,11.2,7.9,11.2,15.2c0,5.7-2.9,11.9-8.8,18.6
          c-17.2-7.8-33.7-11.8-49.5-11.8c-13.2,0-23,4.7-29.5,14.2c-6.4,9.5-9.6,26.5-9.6,51.1c0,29.1,3.5,48.7,10.5,58.9
          C1820.5,204.7,1832.7,209.8,1850.2,209.8z"/>
        <FillPath d="M2010.6,222.8c-6.8,3.5-14.6,6.5-23.4,9.2s-17,4-24.7,4c-13.2,0-22.9-3.1-29.1-9.2c-6.2-6.1-9.2-14.7-9.2-25.7
          c0-15.4,5.3-26.8,15.9-34.3c10.6-7.5,27.3-11.2,50-11.2h14.9c-0.8-14-3.1-23.6-7-28.8c-3.8-5.1-9.6-7.7-17.2-7.7
          c-4.2,0-8.8,0.5-13.7,1.6c-3.2,6-5.1,9.2-5.7,9.6c-0.6,0.4-3.7,1.2-9.4,2.5c-5.7,1.3-9.7,0.9-12.1-1.2c-2.4-2.1-4.8-5.8-7.2-11.1
          c-2.4-5.3-3.6-9.8-3.6-13.4c0-2.3,0.9-4.1,2.8-5.3c1.9-1.3,8.8-2.6,20.7-4.1c11.9-1.5,24.7-2.2,38.1-2.2c16,0,28.6,2.8,37.7,8.3
          c9.1,5.5,13.7,16.2,13.7,32.1v36.2c0,20.1,0.9,32.5,2.6,37.1c1.7,4.6,4.8,6.4,9.3,5.4c1.4,2.6,2.1,5.1,2.1,7.5
          c0,4.1-2.4,7.2-7.3,9.4c-4.9,2.1-11.5,3.2-19.8,3.2c-3.6,0-6.5-0.5-8.6-1.4C2018.3,232.4,2015.1,228.9,2010.6,222.8z M2005,177.4
          c-6.6-1.4-13.2-2.1-20-2.1c-7.7,0-13.1,1.3-16.4,3.8c-3.3,2.6-4.9,7.4-4.9,14.5c0,6.2,1.6,10.9,4.9,14c3.2,3.1,8,4.7,14.2,4.7
          c4.9,0,9.3-1,13.1-3.1c3.8-2.1,6.3-5.1,7.5-9c1.2-3.9,1.8-8.9,1.8-15.1V177.4z"/>
        <FillPath d="M2118.7,95.6c0.5,3.5,0.5,7.2,0,11.3c7.7-3.7,15.4-6.8,23-9.2c7.6-2.4,15.9-3.6,24.8-3.6
          c17.6,0,30.6,4.9,38.9,14.6s12.5,25,12.5,46c0,28.2-6.2,48.8-18.7,61.8c-12.4,13-29.1,19.6-49.8,19.6c-5.2,0-9.4-0.2-12.5-0.5
          c-3.1-0.4-5.5-0.7-7.2-1.1c0,13.5,0.2,26.9,0.7,40.3h19c1.5,3,2.3,6.4,2.3,10.1c0,3.2-0.7,5.4-2,6.7c-1.3,1.3-3.9,1.9-7.7,1.9H2075
          c-1.6-3.8-2.5-7.4-2.5-10.8c0-2.8,0.6-4.9,1.9-6.1c1.3-1.2,3.3-1.8,6.2-1.8h10c0.5-13.4,0.7-31.3,0.7-53.6V168
          c0-25.5-1.2-40.6-3.6-45.3c-2.4-4.6-6.6-7-12.6-7c-1.6-3.6-2.5-7.3-2.5-10.9c0-3.3,1.2-5.4,3.6-6.4
          C2078.6,97.4,2092.8,96.5,2118.7,95.6z M2129.8,206.4c2.9,1.7,5.9,3.2,9.1,4.3c3.1,1.1,7.1,1.7,11.8,1.7c7.9,0,13.9-2.7,18-8.1
          c4.1-5.4,6.1-17.1,6.1-35.1c0-21.3-2.1-35.2-6.2-41.7c-4.1-6.5-9.9-9.7-17.3-9.7c-4.3,0-8.2,1.1-11.8,3.3
          c-3.6,2.2-6.1,5.9-7.5,10.9c-1.5,5-2.2,11.9-2.2,20.8V206.4z"/>
        <FillPath d="M2293.3,214.6h16.1c1.5,3,2.3,6.4,2.3,10.1c0,3.2-0.7,5.4-2,6.7c-1.3,1.3-3.9,1.9-7.7,1.9h-65.6
          c-1.6-3.8-2.3-7.4-2.3-10.8c0-2.8,0.6-4.9,1.9-6.1c1.3-1.2,3.3-1.8,6.2-1.8h11.2c0.5-11.7,0.7-24.1,0.7-37.3
          c0-20.1-0.2-33.5-0.5-40.1c-0.3-6.6-1-11.4-2.1-14.5c-1.1-3.1-2.8-5-5.1-5.7c-2.3-0.8-5.6-1.2-10-1.2c-1.6-2.6-2.3-5.5-2.3-8.9
          c0-5.1,2.1-8.2,6.3-9.4c4.2-1.2,12.7-1.8,25.6-1.8c9.7,0,16.2,0.4,19.8,1.1c3.5,0.7,5.5,2.2,6,4.3c0.5,2.1,0.8,5.1,0.8,9v67.3
          C2292.6,190.5,2292.9,202.9,2293.3,214.6z M2268.3,77.9c-8.2,0-14.1-1.6-17.8-4.7c-3.6-3.1-5.5-7.6-5.5-13.3c0-6,2.3-11,7-14.8
          c4.6-3.9,11.3-5.8,19.8-5.8c8.8,0,14.9,1.7,18.6,5c3.6,3.3,5.5,7.7,5.5,13.1c0,5.9-2.2,10.8-6.6,14.8
          C2285,75.9,2278,77.9,2268.3,77.9z"/>
        <FillPath d="M2332.1,98.1h9.6l13.5-41.2c4.6,1.4,9,3.1,12.9,5.3s7,4.7,9,7.5c2.1,2.8,3.1,9.8,3.1,20.9v7.4h46.2
          c1.5,3.6,2.3,7.5,2.3,11.6c0,3.5-0.6,5.8-1.9,7c-1.3,1.2-3.7,1.8-7.4,1.8h-39.2v54c0,15.7,1.3,26.3,4,32c2.6,5.7,7,8.6,13.2,8.8
          c6.2,0.2,13.6-1.9,22.5-6.5c2.6,3.6,4,7.6,4,12.2c0,6.2-3.3,10.4-9.8,12.5c-6.5,2.1-16.7,3.2-30.4,3.2c-15,0-25.8-3-32.2-9
          s-9.6-17.7-9.6-35v-23.8c0-15-0.5-31.2-1.4-48.4h-13.9c-1.6-3.9-2.5-7.9-2.5-11.9c0-3.1,0.6-5.3,1.8-6.6
          C2327.2,98.7,2329.2,98.1,2332.1,98.1z"/>
        <FillPath d="M2530.1,222.8c-6.8,3.5-14.6,6.5-23.4,9.2s-17,4-24.7,4c-13.2,0-22.9-3.1-29.1-9.2c-6.2-6.1-9.2-14.7-9.2-25.7
          c0-15.4,5.3-26.8,15.9-34.3c10.6-7.5,27.3-11.2,50-11.2h14.9c-0.8-14-3.1-23.6-7-28.8c-3.8-5.1-9.6-7.7-17.2-7.7
          c-4.2,0-8.8,0.5-13.7,1.6c-3.2,6-5.1,9.2-5.7,9.6c-0.6,0.4-3.7,1.2-9.4,2.5c-5.7,1.3-9.7,0.9-12.1-1.2c-2.4-2.1-4.8-5.8-7.2-11.1
          c-2.4-5.3-3.6-9.8-3.6-13.4c0-2.3,0.9-4.1,2.8-5.3c1.9-1.3,8.8-2.6,20.7-4.1c11.9-1.5,24.7-2.2,38.1-2.2c16,0,28.6,2.8,37.7,8.3
          c9.1,5.5,13.7,16.2,13.7,32.1v36.2c0,20.1,0.9,32.5,2.6,37.1c1.7,4.6,4.8,6.4,9.3,5.4c1.4,2.6,2.1,5.1,2.1,7.5
          c0,4.1-2.4,7.2-7.3,9.4c-4.9,2.1-11.5,3.2-19.8,3.2c-3.6,0-6.5-0.5-8.6-1.4C2537.9,232.4,2534.6,228.9,2530.1,222.8z M2524.5,177.4
          c-6.6-1.4-13.2-2.1-20-2.1c-7.7,0-13.1,1.3-16.4,3.8c-3.3,2.6-4.9,7.4-4.9,14.5c0,6.2,1.6,10.9,4.9,14c3.2,3.1,8,4.7,14.2,4.7
          c4.9,0,9.3-1,13.1-3.1c3.8-2.1,6.3-5.1,7.5-9c1.2-3.9,1.8-8.9,1.8-15.1V177.4z"/>
        <FillPath d="M2611.7,167.4c0-41.4-0.2-67.4-0.5-77.9c-0.4-10.6-1-17.8-2.1-21.7c-1-3.9-2.8-6.4-5.3-7.5
          c-2.5-1.1-5.8-1.7-9.8-1.7c-1.6-3.7-2.5-7.5-2.5-11.2c0-3.8,1.4-6.3,4.3-7.3c2.9-1,12.1-1.6,27.5-1.6c9.7,0,16.3,0.4,19.8,1.2
          c3.6,0.8,5.6,2.2,6.1,4.3c0.5,2.1,0.8,5.1,0.8,8.9V158c0,26.1,0.2,44.9,0.7,56.6h17.5c1.5,3,2.3,6.4,2.3,10.1c0,3.2-0.7,5.4-2,6.7
          c-1.3,1.3-3.9,1.9-7.7,1.9h-66.9c-1.6-3.8-2.5-7.4-2.5-10.8c0-2.8,0.6-4.9,1.9-6.1c1.3-1.2,3.3-1.8,6.2-1.8h11.2
          C2611.5,202.9,2611.7,187.2,2611.7,167.4z"/>
     </svg>
   )
  }
}
export default Logo;
