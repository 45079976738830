/**
  Utility function to calculate column span widths on the fly
*/
export const getColumnSpanStyles = (props) => {
  const defaultMediaWidths = {
    small: 576,
    medium: 768,
    large: 992,
    xlarge: 1200,
    xxlarge: 1600
  };
  const { spans, mediaWidths = defaultMediaWidths, gridSize = 24 } = props;

  const keys = Object.keys(spans);

  let columnStyles = {};

  for (const key of keys) {
    const item = spans[key];
    if (typeof(item) === 'number' && (item > 0 && item <= gridSize)) {
      const widthPercent = ((item * 100)/gridSize).toFixed(5);
      if (key === 'default') {
        const spanStyle = {
          flex: `0 0 ${widthPercent}%`,
          maxWidth: `${widthPercent}%`
        }
        columnStyles = { ...columnStyles, ...spanStyle };
      } else if (mediaWidths[key]) {
        let spanStyle = {};
        spanStyle[`@media (min-width: ${mediaWidths[key]}px)`] = {
          flex: `0 0 ${widthPercent}%`,
          maxWidth: `${widthPercent}%`
        };
        columnStyles = { ...columnStyles, ...spanStyle };
      }
    }
  }
  return columnStyles;
};
