import React, { Component } from 'react';
import { Division } from '../core';
import * as animations from '../../../styles/animations';


class ScrollerMouse extends Component {

  static defaultProps = {
    styles: {
      color: 'white'
    }
  }

  render() {
    const styles = {
      container: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 2,
        display: 'none',
        '@media (min-width: 992px)': {
          display: 'block',
        }
      },
      scroller: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: 'auto',
        width :34,
        height: 180,
        cursor: 'pointer'
      },
      mouse: {
        width: 3,
        padding: '10px 12px',
        height: 24,
        border: `2px solid ${this.props.styles.color}`,
        borderRadius: 15,
        opacity: 0.75,
        boxSizing: 'content-box'
      },
      ball: [{
        width: 3,
        height: 8,
        borderRadius: '25%',
        backgroundColor: `${this.props.styles.color}`,
      }, animations.scroll],
      ...this.props.styles
    };

    return (
      <Division {...this.props} styles={styles.container}>
        <Division styles={styles.scroller}>
          <Division styles={styles.mouse}>
            <Division styles={styles.ball} />
          </Division>
        </Division>
      </Division>
    );
  }
}

export default ScrollerMouse;
