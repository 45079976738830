export const contactDetails = {
  address: [
    {
      title: 'Address',
      lines: [ 
        '101A Clay Steet #104',
        'San Francisco, CA 94111'
      ]
    }
  ],
  phone: [
    {
      title: 'Phone',
      number: '+1 (470)-222-4528'
    }
  ],
  email: [
    {
      title: 'Email',
      address: 'contact@doubledown.vc'
    }
  ]
}