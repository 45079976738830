export const portfolioCompanies = [
  {
    name: 'Zoomcar',
    cohortNumber: '01',
    logo: '/assets/portfolio/logos/zoomcar.png',
    uri: 'http://www.zoomcar.in'
  },
  {
    name: 'Flintobox',
    cohortNumber: '02',
    logo: '/assets/portfolio/logos/flintobox.png',
    uri: 'https://flintobox.com'
  },
  {
    name: 'Springboard',
    cohortNumber: '11',
    logo: '/assets/portfolio/logos/springboard.png',
    uri: 'https://www.springboard.com'
  },
  {
    name: 'InstaOffice',
    cohortNumber: '14',
    logo: '/assets/portfolio/logos/instaoffice.png',
    uri: 'https://www.instaoffice.in'
  },
  {
    name: 'Wealthy',
    cohortNumber: '15',
    logo: '/assets/portfolio/logos/wealthy.png',
    uri:'https://www.wealthy.in'
  },
  {
    name: 'Chakr Innovation',
    cohortNumber: '16',
    logo: '/assets/portfolio/logos/chakr.png',
    uri: 'https://chakr.in'
  },
  {
    name: 'Doxper',
    cohortNumber: '18',
    logo: '/assets/portfolio/logos/doxper.png',
    uri: 'http://doxper.com'
  },
  {
    name: 'Oorjan',
    cohortNumber: '19',
    logo: '/assets/portfolio/logos/oorjan.png',
    uri: 'https://www.oorjan.com'
  },
  {
    name: 'MyMandir',
    cohortNumber: '21',
    logo: '/assets/portfolio/logos/mymandir.png',
    uri: 'https://www.mymandir.com'
  },
  {
    name: 'OurHealthMate',
    cohortNumber: '23',
    logo: '/assets/portfolio/logos/ourhealthmate.png',
    uri: 'https://www.ourhealthmate.com'
  },
  {
    name: 'Actionable Science',
    cohortNumber: '24',
    logo: '/assets/portfolio/logos/actionablescience.png',
    uri: 'https://www.actionable-science.com'
  },
  {
    name: 'Sunsama',
    cohortNumber: '25',
    logo: '/assets/portfolio/logos/sunsama.png',
    uri: 'https://www.sunsama.com'
  },
  {
    name: 'Brew',
    cohortNumber: '26',
    logo: '/assets/portfolio/logos/brew.png',
    uri: 'https://www.brew.com'
  },
  {
    name: 'Buy Me A Coffee',
    cohortNumber: '27',
    logo: '/assets/portfolio/logos/buymeacoffee.png',
    uri: 'https://www.buymeacoffee.com'
  },
  {
    name: 'Avanti',
    cohortNumber: '28',
    logo: '/assets/portfolio/logos/avanti.png',
    uri: 'https://www.avanti.in'
  },
  {
    name: 'Digi-Prex',
    cohortNumber: '29',
    logo: '/assets/portfolio/logos/digi-prex.png',
    uri: 'https://www.digi-prex.com'
  },
  {
    name: 'Forbidden Foods',
    cohortNumber: '30',
    logo: '/assets/portfolio/logos/ff.png',
    uri: 'https://www.forbiddenfoods.in'
  }
];
