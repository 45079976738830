import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { themes } from '../../styles/theme';

class LinkedIn extends Component {

  static propTypes = {
    title: PropTypes.string,
    iconStyle: PropTypes.object
  }

  static defaultProps = {
    title: 'LinkedIn',
    iconStyle: {}
  }

  render() {

    const primaryColor = this.props.iconStyle.primaryColor || themes.primary.primaryColor;
    const commonStyle = {
    };

    const Path = styled.path(commonStyle,
      props => ({
        ...props.styles
      })
    );

    const SVG = styled.svg({},
      props => ({
        ...props.styles
      })
    );

    return (
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        className={this.props.className}
        aria-labelledby="title"
     	  viewBox="0 0 430.117 430.117"
        styles={this.props.styles}
      >
        <title>{this.props.title}</title>
        <Path d="M430.117,261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707
		c-25.473,0-40.632,17.142-47.301,33.724c-2.432,5.928-3.058,14.179-3.058,22.477V420.56h-92.219c0,0,1.242-251.285,0-277.32h92.21
		v39.309c-0.187,0.294-0.43,0.611-0.606,0.896h0.606v-0.896c12.251-18.869,34.13-45.824,83.102-45.824
		C384.633,136.724,430.117,176.361,430.117,261.543z M52.183,9.558C20.635,9.558,0,30.251,0,57.463
		c0,26.619,20.038,47.94,50.959,47.94h0.616c32.159,0,52.159-21.317,52.159-47.94C103.128,30.251,83.734,9.558,52.183,9.558z
     M5.477,420.56h92.184v-277.32H5.477V420.56z"
          styles={{fill: primaryColor, ...this.props.iconStyle}}
        />
     </SVG>
   )
  }
}

export default LinkedIn;
