import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { themes } from '../../styles/theme';

class Phone extends Component {

  static propTypes = {
    title: PropTypes.string,
    iconStyle: PropTypes.object
  }

  static defaultProps = {
    title: 'Phone',
    iconStyle: {}
  }

  render() {

    const primaryColor = this.props.iconStyle.primaryColor
                          || themes.primary.primaryColor;
    const secondaryColor = this.props.iconStyle.secondaryColor
                          || themes.primary.primaryLightColor;

    const commonStyle = {
      strokeWidth: 3,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
      fill: 'none'
    };

    const Path = styled.path(commonStyle, props => ({...props.styles}));
    const Line = styled.line(commonStyle, props => ({...props.styles}));
    const SVG = styled.svg(commonStyle, props => ({...props.styles}));

    return (
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        className={this.props.className}
        aria-labelledby="title"
     	  viewBox="0 0 120 120"
        styles={this.props.styles}
      >
        <title>{this.props.title}</title>

        <Path d="M19.3,28.1L19.3,28.1C14,33.4,10.9,40.6,11,48.2c0.2,10.7,5.1,26.6,24.7,46.2c11.5,11.5,21.5,17.7,29.8,20.7
	c11.8,4.3,25.1,1.2,34-7.7l0,0c1.8-1.8,1.8-4.8,0-6.6L85.2,86.5c-1.8-1.8-4.7-1.8-6.5-0.1l-8.6,8c0,0-25.3-9.2-37.6-37.6l7.7-7.7
	c2.1-2.1,2.1-5.5,0-7.6L26.9,28.1C24.8,26,21.4,26,19.3,28.1z"
          styles={{fill: secondaryColor, opacity: 0.1}}
        />
        <Path d="M9,30.9L9,30.9C3.7,36.2,0.6,43.4,0.7,51c0.2,10.7,5.1,26.6,24.7,46.2c11.5,11.5,21.5,17.7,29.8,20.7
	c11.8,4.3,25.1,1.2,34-7.7l0,0c1.8-1.8,1.8-4.8,0-6.6L74.9,89.2c-1.8-1.8-4.7-1.8-6.5-0.1l-8.6,8c0,0-25.3-9.2-37.6-37.6l7.7-7.7
	c2.1-2.1,2.1-5.5,0-7.6L16.6,30.9C14.5,28.8,11.1,28.8,9,30.9z"
          styles={{fill: primaryColor}}
        />
        <Line x1="9" y1="32.2" x2="23.4" y2="46.6"
          styles={{stroke: secondaryColor}}        
        />
        <Line x1="88.7" y1="111.9" x2="74" y2="97.2"
          styles={{stroke: secondaryColor}}        
        />
        <Path d="M61.3,97.1l-5.2,5.3c0,0-8.9-2.9-18.7-12.4"
          styles={{stroke: secondaryColor}}                
        />
        <Path d="M34.7,86.2"
          styles={{stroke: secondaryColor}}                        
        />
        <Path d="M32.5,84.9c0,0-3.7-3.9-5.9-7.7"
          styles={{stroke: secondaryColor}}                                
        />
        <g>
          <Path d="M64.3,1.7c1.7-0.1,3.3-0.1,5,0"
            styles={{stroke: secondaryColor}}                                         
          />
          <Path d="M80.4,3.5c5.3,1.5,10.5,3.8,15.3,6.9"
            styles={{stroke: secondaryColor, strokeDasharray: '11.2267,11.2267'}}                                                   
          />
          <Path d="M100.2,13.8c1.3,1.1,2.5,2.2,3.7,3.4"
            styles={{stroke: secondaryColor}}                                                   
          />
		    </g>
        <Path d="M103.9,17.1c19.2,19.2,19.2,50.4,0,69.7"
            styles={{stroke: secondaryColor}}                                                 
        />
        <Path d="M28.9,17.1c9.8-9.8,22.5-15,35.4-15.5"
            styles={{stroke: secondaryColor}}                                                 
        />
        <Line x1="97.5" y1="71.1" x2="93.7" y2="69.2"
            styles={{stroke: secondaryColor}}                                                 
        />
        <Line x1="101.5" y1="49.1" x2="97.4" y2="50.1"
            styles={{stroke: secondaryColor}}                                                 
        />
        <Line x1="49.4" y1="23.1" x2="51.1" y2="28.5"
            styles={{stroke: secondaryColor}}                                                 
        />
        <Line x1="71.4" y1="19" x2="70.4" y2="23.1"
            styles={{stroke: secondaryColor}}                                                 
        />
        <Line x1="91.9" y1="29.2" x2="87.3" y2="33.8"
            styles={{stroke: secondaryColor}}                                                 
        />
        <Path d="M41.2,29.2c14-14,36.6-14,50.6,0s14,36.6,0,50.6"
            styles={{stroke: primaryColor}}                                                 
        />
        <Path d="M107.9,38.6c0,0,5.5,11.5,0.8,25.9"
            styles={{stroke: secondaryColor}}                                         
        />
        <Path d="M42.1,17.4c0,0,3.4-2.6,7.3-3.6"
            styles={{stroke: secondaryColor}}                                                 
        />
        <Line x1="104.2" y1="31.8" x2="100.8" y2="26.5"
            styles={{stroke: secondaryColor}}                                                 
        />
     </SVG>
   )
  }
}

export default Phone;