export const commonStyles = {
  fullWidthAbsolute: {
    position: 'absolute',
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  positionItemsMiddle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  column: {
    position: 'relative',
    width: '100%',
    minHeight: 1,
    paddingRight: 15,
    paddingLeft: 15,
    marginBottom: '3rem'
  },
  section: {
    overflow: 'hidden',
    '@media (max-width: 575px)': {
      padding: '2rem 0'
    },
  },
  content: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (max-width: 575px)': {
      paddingLeft: 30,
      paddingRight: 30
    },
    '@media (min-width: 576px)': {
      paddingLeft: 15,
      paddingRight: 15,  
      maxWidth: 540
    },
    '@media (min-width: 768px)': {
      maxWidth: 720
    },
    '@media (min-width: 992px)': {
      maxWidth: 960
    },
    '@media (min-width: 1200px)': {
      maxWidth: 1140
    }
  },
  header: {
    maxWidth: '100%',
    overflow: 'hidden'
  },
  tagline: {
    textTransform: 'uppercase',
    fontWeight: 500,
    '@media (max-width: 575px)': {
      fontSize: '0.8rem'
    }
  },
  marginsCenter: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  marginsMiddle: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  sectionTitle: {
    textAlign: 'center',
    margin: '0 auto 1rem',
    '@media (min-width: 768px)': {
    }
  },
  sectionHeading: {
    '@media (max-width: 575px)': {
      lineHeight: 1.2
    }
  },
  sectionDescription: {
    opacity: 0.6,
    marginBottom: '2rem'
  }
}
