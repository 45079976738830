export const teamMembers = {
  partners: [
    {
      name: 'P. N. Raju',
      title: 'Founder & General Partner',
      profilePic: '/assets/team/raju.jpg',
      bio: `
        Raju leads DoubleDown's investments in the US and brings with him over 10+ years of experience in product management, strategy
        and startup investing.
      `,
      linkedIn: 'https://www.linkedin.com/in/pnraju',
      twitter: 'https://twitter.com/pnraju',
      angelList: 'https://angel.co/pnraju',
      blog: ''
    },
    {
      name: 'Ankur Shrivastava',
      title: 'Founder & General Partner',
      profilePic: '/assets/team/ankur.jpg',
      bio:  `
        Ankur leads DoubleDown's investments in India and brings with him over 14+ years of experience in strategy consulting
        and startup investing.
      `,
      linkedIn: 'https://www.linkedin.com/in/srivast',
      twitter: 'https://twitter.com/ankoors',
      angelList: 'https://angel.co/ankurshrivastava',
      blog: ''
    },
  ],
  mentors: [

  ],
  angels: [

  ]
}