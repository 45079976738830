import { keyframes, css } from '@emotion/core';

// Keyframes and animation for mouse scroller
const _scroll_keyframes = keyframes`
  0% { opacity: 0 }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0; }
`;

export const scroll = css`
  animation: ${_scroll_keyframes} 2.2s cubic-bezier(.15,.41,.69,.94) infinite;
`;

// Keyframes and animation for Typed.js
const _blink_keyframes = keyframes`
  0% { opacity: 1 }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

export const blink = css`
  animation: ${_blink_keyframes} 0.7s infinite;
`;
