// Fisher-Yates (aka Knuth) Shuffle algorithm to randomize
// the floatingImages array
const shuffleArray = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

const getRandomTransitionTime = (baseTime) => (Math.random() + Math.random() + Math.random() + baseTime).toFixed(2);

const defaultPositions = [
  { x: 0.10, y: 0.50, size: 8 },
  { x: 0.25, y: 0.20, size: 6 },  
  { x: 0.50, y: 0.20, size: 10 },
  { x: 0.75, y: 0.20, size: 6 },
  { x: 0.90, y: 0.50, size: 8 },
  { x: 0.75, y: 0.80, size: 6 },
  { x: 0.50, y: 0.80, size: 10 },
  { x: 0.25, y: 0.80, size: 6 }
]

export const getStyledFloatingImages = (floatingImages, randomize = true, positions = defaultPositions) => {

  const styleImages = randomize ? shuffleArray(floatingImages) : floatingImages;
  let styledFloatingImages = [];
  styleImages.forEach((image, index) => {
    let position = {};
    if ((index+1) <= positions.length) {
      position = positions[index];
      const transition = `all ${getRandomTransitionTime(2)}s ease`;
      const transform = `translate(${(position.x * 100)-50}vw, ${(position.y * 100)-50}vh)`;
      styledFloatingImages.push(
        {
          image: image,
          styles: {
            wrapper: {
              left: '50%',
              top: '50%',
              transition: transition,
              transitionDelay: '0.2s',
              opacity: 0,
              transform: 'translate(0, 0)',
              '.fp-viewing-1-0 &': {
                transform: transform,
                opacity: 1
              },
              '.fp-viewing-2-0 &': {
                transform: 'translate(0, 0)',
                opacity: 0
              }
            },
            container: {
              width: `${position.size}rem`,
              height: `${position.size}rem`
            }
          },
        }
      );
    } 
  });
  return styledFloatingImages
}