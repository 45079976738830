import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { themes } from '../../styles/theme';

class Email extends Component {

  static propTypes = {
    title: PropTypes.string,
    iconStyle: PropTypes.object
  }

  static defaultProps = {
    title: 'Email',
    iconStyle: {}
  }

  render() {

    const primaryColor = this.props.iconStyle.primaryColor
                          || themes.primary.primaryColor;
    const secondaryColor = this.props.iconStyle.secondaryColor
                          || themes.primary.primaryLightColor;

    const commonStyle = {
      strokeWidth: 3,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
      fill: 'none'
    };

    const Path = styled.path(commonStyle, props => ({...props.styles}));
    const Line = styled.line(commonStyle, props => ({...props.styles}));
    const SVG = styled.svg(commonStyle, props => ({...props.styles}));


    return (
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        className={this.props.className}
        aria-labelledby="title"
     	  viewBox="0 0 120 120"
        styles={this.props.styles}
      >
        <title>{this.props.title}</title>

      <g name="top-envelope-shadow">
        <Line x1="25.2" y1="66.2" x2="35.9" y2="66.2"
          styles={{stroke: secondaryColor, opacity: 0.5}}
        />      
        <Line x1="44.3" y1="76.4" x2="33.2" y2="76.4"
          styles={{stroke: secondaryColor, opacity: 0.5}}
        />
        <Line x1="6.4" y1="85.8" x2="11.8" y2="85.8"
          styles={{stroke: secondaryColor, opacity: 0.5}}
        />
        <Line x1="17.1" y1="85.8" x2="33.1" y2="85.8"
          styles={{stroke: secondaryColor, opacity: 0.5}}
        />
        <Line x1="17.1" y1="76.5" x2="1.8" y2="76.5"
          styles={{stroke: secondaryColor, opacity: 0.5}}
        />
        <Line x1="19" y1="66.2" x2="15.4" y2="66.2"
          styles={{stroke: secondaryColor, opacity: 0.5}}
        />
      </g>
      <Path d="M115.7,114.2H38.7c-2.4,0-4.3-2-4.3-4.3v-48c0-2.4,2-4.3,4.3-4.3h76.9c2.4,0,4.3,2,4.3,4.3v48
  C120,112.2,118,114.2,115.7,114.2z"
        styles={{fill: secondaryColor, opacity: 0.2}}
      />
      <Path d="M106.1,102.9H29.2c-2.4,0-4.3-2-4.3-4.3v-48c0-2.4,2-4.3,4.3-4.3h76.9c2.4,0,4.3,2,4.3,4.3v48
	C110.4,100.9,108.5,102.9,106.1,102.9z"
        styles={{fill: primaryColor}}
      />
      <Path d="M106.1,102.9H29.2c-2.4,0-4.3-2-4.3-4.3v-48c0-2.4,2-4.3,4.3-4.3h76.9c2.4,0,4.3,2,4.3,4.3v48
	C110.4,100.9,108.5,102.9,106.1,102.9z"
        styles={{stroke: primaryColor}}
      />
      <g name="bottom-envelope-shadow">
        <Line x1="78.4" y1="81.6" x2="109.6" y2="101.5"
          styles={{stroke: secondaryColor}}
        />
        <Line x1="56.9" y1="81.6" x2="25.6" y2="101.5"
          styles={{stroke: secondaryColor}}
        />
        <Line x1="25.6" y1="47.9" x2="67.6" y2="78.6"
          styles={{stroke: secondaryColor}}
        />
        <Line x1="109.6" y1="47.9" x2="67.6" y2="78.6"
          styles={{stroke: secondaryColor}}
        />
      </g>
      <Line x1="70" y1="95.1" x2="55.8" y2="95.1"
        styles={{stroke: secondaryColor, opacity: 0.5}}
      />
      <Line x1="76.2" y1="95.1" x2="82.6" y2="95.1"
        styles={{stroke: secondaryColor, opacity: 0.5}}
      />
      <Path d="M89.9,107.9"
        styles={{stroke: secondaryColor}}
      />
      <Line x1="72.5" y1="36.3" x2="90.4" y2="36.3"
        styles={{stroke: secondaryColor, opacity: 0.5}}
      />
      <Line x1="108.7" y1="27.9" x2="103.4" y2="27.9"
        styles={{stroke: secondaryColor, opacity: 0.5}}
      />
      <Line x1="98" y1="27.9" x2="82.1" y2="27.9"
        styles={{stroke: secondaryColor, opacity: 0.5}}
      />
      <Line x1="97" y1="36.2" x2="112.4" y2="36.2"
        styles={{stroke: secondaryColor, opacity: 0.5}}
      />
      <Path d="M5,41.6V14.7c0-2.1,1.8-3.9,3.9-3.9h77.8c2.2,0,3.9,1.7,3.9,3.9v5.5"
        styles={{stroke: secondaryColor}}      
      />
      <g>
        <Line x1="17.5" y1="20.3" x2="47.4" y2="39.2"
          styles={{stroke: secondaryColor}}      
        />
        <Line x1="78" y1="20" x2="47.6" y2="39.2"
          styles={{stroke: secondaryColor}}      
        />
      </g>
      <Path d="M25.2,5.8"
        styles={{stroke: secondaryColor}}
      />
     </SVG>
   )
  }
}

export default Email;
