/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled';

import { commonStyles } from '../../styles/common';
import { getColumnSpanStyles } from '../../styles/utils';
/**
  Function to create custom React components, that can have styles attached to
  them, using Emotion.
*/
export const createStyledElement = (as = 'div', defaultStyle = {}, defaultClassName = '',
          commonModifier = () => {}, customModifier = () => {}) => {
  const StyledElement = styled(as)(defaultStyle, commonModifier);
  return (props) => {
    const customStyles = customModifier(props);
    const className = defaultClassName ? `${defaultClassName} ${props.className || ''}` : props.className;
    return (
      <StyledElement css={[customStyles, props.styles]} {...props} className={className}>
        {props.children}
      </StyledElement>
    )
  }
}

export const Image = createStyledElement('img');
export const Anchor = createStyledElement('a');
export const Span = createStyledElement('span');
export const Button = createStyledElement('button');
export const Division = createStyledElement('div');
export const Paragraph = createStyledElement('p');
export const Heading = createStyledElement('h1');
export const Navigation = createStyledElement('nav');
export const Header = createStyledElement('header', commonStyles.header);

export const Content = createStyledElement('div', commonStyles.content);
export const Section = createStyledElement('section', {}, 'section');
export const Slide = createStyledElement('article', {}, 'slide');
export const Article = createStyledElement('article');
export const Main = createStyledElement('main');
export const Aside = createStyledElement('aside');
export const Row = createStyledElement('div', commonStyles.row);
export const Column = createStyledElement('div', commonStyles.column, '', () => {}, getColumnSpanStyles);
