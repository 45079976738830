import React, { Component } from 'react';
import { Carousel } from 'antd';

import { Section, Slide, Content, Division, Heading } from '../elements/core';
import NewsSnippet from './elements/NewsSnippet';
import { commonStyles } from '../../styles/common';

// Imported News element from Classic Business homepage. Also look at Classic Marketing, Classic Consulting.
class NewsSection extends Component {

  state = {
    newsStories: []
  };

  componentDidMount() {
    this.setState({
      newsStories: [...this.props.newsStories].sort((a, b) => {return Date.parse(b.date) - Date.parse(a.date)})
    });
  }

  render() {
    const innerWidth = window.innerWidth;
    let slidesToShow;
    if (innerWidth < 576) {
      slidesToShow = 1
    } else if (innerWidth < 992) {
      slidesToShow = 2
    } else {
      slidesToShow = 4
    }
    const settings = {
      arrows: true,
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: 1
    };    
    const { sectionCopy = {} } = this.props;
    return (
      <Section className="section" styles={{...commonStyles.section, ...customStyles.section}} data-role="news">
        <Slide className="slide" styles={customStyles.slide}>
          <Content styles={customStyles.content}>
            <Division styles={commonStyles.sectionTitle}>
              <Division styles={commonStyles.tagline}>{sectionCopy.tagline}</Division>
              <Heading>{sectionCopy.heading}</Heading>
            </Division>
            <Division styles={commonStyles.sectionDescription}>
              <Heading as="h5">{sectionCopy.description}</Heading>
            </Division>
            <Carousel {...settings} style={{marginRight: '-1rem'}}>
              {
                this.state.newsStories.map((news, index) => (
                  <NewsSnippet key={index} news={news} />
                ))
              }
            </Carousel>
          </Content>
        </Slide>
      </Section>
    );
  }
}

const customStyles = {
  section: {
    backgroundColor: '#006a7c'
  },
  content: {
    color: 'white',
    transform: `scale(${window.innerHeight > 800 ? 1 : (window.innerHeight/800)})`
  }
};

export default NewsSection;
