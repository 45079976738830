/*
  Keeping website copy in this file to make easy edits
*/
export const landingCopy = {
  cover: {
    tagline: 'Early-Stage Investor, Long-Term Partner',
    heading: 'We partner with ambitious and gritty entrepreneurs, helping them',
    goals: [      
      'disrupt the status quo',
      'reshape existing industries',
      'drive the world forward',
      'build the future'
    ],
    description: `
      We invest in early-stage technology startups and support founders in their pursuit of big, 
      world-changing ideas. In addition to capital, we provide our companies strategic & operational 
      guidance, strong industry connections and a unique global network of mentors & partners.
    `,
    button: {
      category: 'Explore',
      callToAction: 'Learn More About Us'
    }
  },
  founderStories: {
    tagline: 'Founders First',
    heading: 'Built with founders in mind',
    description: `
      We started DoubleDown Capital with a single-minded focus on helping founders succeed. We know from 
      experience how hard building successful companies can be and we like to go the extra mile, 
      rain or shine, to give our founders an unfair advantage.
    `
  },
  portfolio: {
    tagline: 'Our Portfolio',
    heading: 'A growing network of category leaders',
    description: `
      Our portfolio companies come from different sectors but they all have one thing
      in common - they are led by strong teams working on transformational ideas with the goal of 
      becoming leaders in their categories.
    `
  },
  manifesto: {
    tagline: 'Our Investment Manifesto',
    heading: 'What we invest in',
    description: `
      We are a sector-agnostic, thesis-driven venture capital firm. While we rely on founders to 
      convince us of their vision of the future, we look at certain key elements before 
      making investment decisions:
    `,
    principles: {
      stage: {
        title: 'Stage',
        belief: `
          We invest in early-stage startups that use mature technologies and efficient business 
          models to drive large, transformational market and industry changes.   
        `
      },
      geography: {
        title: 'Geography',
        belief: `
          We have a strong bias for companies that are looking to leverage the growing digitally
          connected populations or the globally aware talent in India and other emerging markets. 
        `
      },
      thesis: {
        title: 'Thesis',
        belief: `
          We back companies that create, broaden and enhance access to resources, services, 
          productivity and well-being through delightful user experiences.
        `
      },
    },
    callToAction: `
      We write $25,000-$1,000,000 checks in startups that meet our investment manifesto.
    `
  },
  stats: {
    tagline: 'Our Story In Numbers',
    heading: 'A few of the many metrics we track',
    description: `
      We measure our success based on the success of our founders and companies. We track
      a lot of metrics and help our founders stay on top of theirs.
    `
  },
  team: {
    tagline: 'Our Team',
    heading: 'A wealth of knowledge and experience',
    description: `
      Our team brings with it a unique set of skills and experiences spanning multiple 
      geographies and industries. We've also built a global network of mentors 
      & partners to help us support our founders better. 
    `
  },
  news: {
    tagline: 'In the news',
    heading: 'Recent press coverage',
    description: `
      The media loves talking about us and our portfolio companies. Here's a selection of recent
      press coverage from across the internet.
    `
  },
  footer: {
    tagline: 'Get in touch',
    heading: 'A few ways to contact us',
    description: `
      If you want to share your ideas with us or show us what you are working on, the best and fastest
      way to reach us is through a personal referral. If you can't find someone to refer you to us, please use 
      the contact information below to reach us.
    `
  }
};