import React, { Component } from 'react';
import { Carousel } from 'antd';

import { Section, Slide, Content, Division, Heading } from '../elements/core';
import MemberProfile from './elements/MemberProfile';
import { commonStyles } from '../../styles/common';

// Look at Classic Business homepage
class TeamSection extends Component {

  render() {
    const settings = {
      arrows: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: this.props.rendersFullPage ? 2 : 1,
      slidesToScroll: 1
    };
    const { sectionCopy = {} } = this.props;
    return (
      <Section className="section" styles={{...commonStyles.section, ...customStyles.section}} data-role="team">
        <Slide className="slide" styles={customStyles.slide}>
          <Content styles={customStyles.content}>
            <Division styles={commonStyles.sectionTitle}>
              <Division styles={commonStyles.tagline}>{sectionCopy.tagline}</Division>
              <Heading>{sectionCopy.heading}</Heading>
            </Division>
            <Division styles={commonStyles.sectionDescription}>
              <Heading as="h5">{sectionCopy.description}</Heading>
            </Division>
            <Carousel {...settings}>
            {
              this.props.teamMembers.partners.map((member, index) => (
                <MemberProfile key={index} member={member} />
              ))
            }
            </Carousel>
          </Content>
        </Slide>
      </Section>
    );
  }
}

const customStyles = {
  section: {
    backgroundColor: '#41456a'
  },
  content: {
    color: 'white',
    transform: `scale(${window.innerHeight > 800 ? 1 : (window.innerHeight/800)})`
  }
};

export default TeamSection;
