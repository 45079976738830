import React, { Component } from 'react';

import { Section, Slide, Content, Division, Heading, Row } from '../elements/core';
import PortfolioCard from './elements/PortfolioCard';
import { commonStyles } from '../../styles/common';

class PortfolioSection extends Component {
  
  render() {
    const { sectionCopy = {} } = this.props;
    return (
      <Section styles={{...commonStyles.section, ...customStyles.section}} data-role="portfolio">
        <Slide styles={customStyles.slide}>
          <Content styles={customStyles.content}>
            <Division styles={commonStyles.sectionTitle}>
              <Division styles={commonStyles.tagline}>{sectionCopy.tagline}</Division>
              <Heading styles={commonStyles.sectionHeading}>{sectionCopy.heading}</Heading>
            </Division>
            <Division styles={{...commonStyles.sectionDescription, ...customStyles.description}}>
              <Heading as="h5">{sectionCopy.description}</Heading>
            </Division>
            <Row styles={customStyles.portfolioContainer}>
              {
                this.props.portfolioCompanies.map((company, index) => (
                  <PortfolioCard key={index} company={company}></PortfolioCard>
                ))
              }
            </Row>
          </Content>
        </Slide>
      </Section>
    );
  }
}

const customStyles = {
  section: {
    background: '#a5c8d1'
  },
  content: {
    color: '#13354e',
    transform: `scale(${window.innerHeight > 800 ? 1 : (window.innerHeight/800)})`
  },
  description: {
    opacity: 0.8
  },
  portfolioContainer: {
  },
};

export default PortfolioSection;
