import React, { Component } from 'react';

import { Row, Column, Division, Anchor, Image } from '../../elements/core';
import * as Icons from '../../../icons/icons';
import { commonStyles } from '../../../styles/common';

class MemberProfile extends Component {

  render() {
    const member = this.props.member;
    return (
        <Row styles={customStyles.container}>
          <Column spans={{small: 8}} styles={customStyles.profilePicContainer}>
            <Image styles={customStyles.profilePic} src={member.profilePic} alt={member.name} />
            <Division styles={customStyles.socialMedia}>
            {
              member.linkedIn
              ? <Anchor styles={{textDecoration: 'none'}} href={member.linkedIn} target={'_blank'}>
                  <Icons.LinkedIn 
                    iconStyle={customStyles.iconStyle} 
                    styles={customStyles.socialIcons} 
                  />
                </Anchor>
              : null
            }
            {
              member.twitter
              ? <Anchor styles={{textDecoration: 'none'}} href={member.twitter} target={'_blank'}>
                  <Icons.Twitter 
                    iconStyle={customStyles.iconStyle} 
                    styles={customStyles.socialIcons} 
                  />
                </Anchor>
              : null
            }
            {
              member.angelList
              ? <Anchor styles={{textDecoration: 'none'}} href={member.angelList} target={'_blank'}>
                  <Icons.AngelList 
                    iconStyle={customStyles.iconStyle} 
                    styles={customStyles.socialIcons} 
                  />
                </Anchor>
              : null
            }
            </Division>
          </Column>
          <Column spans={{small: 16}} styles={customStyles.profile}>
              <Division styles={customStyles.name}>{member.name}</Division>
              <Division styles={customStyles.title}>{member.title}</Division>
              <Division styles={customStyles.bio}>{member.bio}</Division>
          </Column>
        </Row>
    );
  }
}

const customStyles = {
  container: {
  },
  profilePicContainer: {
  },
  profilePic: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '0.25rem',
    ...commonStyles.marginsCenter
  },
  profile: {
    padding: '0 2rem 0 1rem',
    marginBottom: 0,
    textAlign: 'center',
    '@media (min-width: 576px)': {
      display: 'flex',
      alignContent: 'flex-start',
      flexDirection: 'column',
      textAlign: 'left'
    }
  },
  name: {
    fontSize: '1.5rem'
  },
  title: {
    opacity: 0.7,
    textTransform: 'uppercase'
  },
  bio: {
    opacity: 0.5,
    marginTop: '1rem'
  },
  socialMedia: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '1rem'
  },
  socialIcons: {
    height: 18,
    marginRight: '1rem',
    opacity: 0.4,
    '&:hover': {
      opacity: 1
    }
  },
  iconStyle: {
    primaryColor: 'white',
  },
};

export default MemberProfile;