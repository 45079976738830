import React, { Component } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { Global } from '@emotion/core';
import ReactGA from 'react-ga';

import CoverSection from './Cover';
import FounderStoriesSection from './FounderStories';
import PortfolioSection from './Portfolio';
import ManifestoSection from './Manifesto';
import StatsSection from './Stats';
import TeamSection from './Team';
import NewsSection from './News';
import FooterSection from './Footer';

import { fullPageStyles } from '../../styles/vendor';

import { 
  landingCopy, founderStories, portfolioCompanies, 
  fundMetrics, newsStories, teamMembers, contactDetails
} from '../../data/data';

const sections = [
  {
    section: 'cover',
    component: CoverSection,
    props: {
      sectionCopy: landingCopy.cover
    }
  },
  {
    section: 'founders',
    component: FounderStoriesSection,
    props: {
      sectionCopy: landingCopy.founderStories,
      founderStories: founderStories
    }
  },
  {
    section: 'portfolio',
    component: PortfolioSection,
    props: {
      sectionCopy: landingCopy.portfolio,
      portfolioCompanies: portfolioCompanies 
    }
  },
  {
    section: 'manifesto',
    component: ManifestoSection,
    props: {
      sectionCopy: landingCopy.manifesto
    }
  },
  {
    section: 'stats',
    component: StatsSection,
    props: {
      sectionCopy: landingCopy.stats,
      fundMetrics: fundMetrics
    }
  },
  {
    section: 'team',
    component: TeamSection,
    props: {
      sectionCopy: landingCopy.team,
      teamMembers: teamMembers
    }
  },
  {
    section: 'news',
    component: NewsSection,
    props: {
      sectionCopy: landingCopy.news,
      newsStories: newsStories
    }
  },
  {
    section: 'footer',
    component: FooterSection,
    props: {
      sectionCopy: landingCopy.footer,
      contactDetails: contactDetails
    }
  }
]

class LandingContainer extends Component {

  componentDidMount() {
    ReactGA.pageview('/landing');
    if (window.performance) {
      // Gets the number of milliseconds since page load
      // (and rounds the result since the value must be an integer).
      var timeSincePageLoad = Math.round(performance.now());
      ReactGA.timing({
        category: 'Page Load',
        variable: 'load',
        value: timeSincePageLoad
      });
    }
  }

  renderFullpage(responsiveWidth, responsiveHeight) {
    return (
      <ReactFullpage
        licenseKey={'8F573373-D9C64826-B8F75B1F-E104EC30'}
        controlArrows={false}
        navigation={true}
        autoScrolling={true}
        responsiveWidth={responsiveWidth}
        responsiveHeight={responsiveHeight}
        onLeave={(origin, destination, direction) => {
          ReactGA.pageview(`/landing/${sections[destination.index].section}`);
        }}
        render={({ state, fullpageApi: fullpageAPI }) => {
          return (
            <ReactFullpage.Wrapper>
              <Global styles={fullPageStyles} />
              {
                sections.map((section, index) => (
                  <section.component key={index} {...section.props} rendersFullPage={true} fullpageAPI={fullpageAPI} />
                ))
              }
            </ReactFullpage.Wrapper>
          );
        }}
      />
    )
  }

  renderResponsive() {
    return (
      <div>
      {
        sections.map((section, index) => (
          <section.component key={index} {...section.props} />
        ))
      }
    </div>      
    )
  }

  render() {

    const responsiveWidth = 576, responsiveHeight = 400;
    const showFullpage = window.innerWidth >= responsiveWidth && window.innerHeight >= responsiveHeight;
    return (
      <main id="content" role="main">
        { 
          showFullpage 
          ? this.renderFullpage(responsiveWidth, responsiveHeight) 
          : this.renderResponsive()
        }
      </main>
    )
  }

}
export default LandingContainer;
