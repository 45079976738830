/*------------------------------------
  Custom styles for fullPage.js
------------------------------------*/
export const fullPageStyles = {
  '#fp-nav ul li a span': {
    backgroundColor: 'white',
    opacity: 0.6
  },
  '#fp-nav ul li:hover a.active span, #fp-nav ul li a.active span': {
    backgroundColor: 'white',
    opacity: 0.6
  }
}
