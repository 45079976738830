export const newsStories = [
  {
    headline: 'Springboard raises $11 million from Reach Capital, others',
    date: 'Dec 18, 2019',
    category: 'Portfolio',
    uri: 'https://tech.economictimes.indiatimes.com/news/startups/springboard-raises-11-million-from-reach-capital-others/72862712',
    image: 'https://etimg.etb2bimg.com/photo/72862724.cms',
    summary: 'Springboard has raised $11 million (over Rs 78 crore) in funding led by Reach Capital, Pearson Ventures, International Finance Corporation (IFC)',
    publication: {
      name: 'Economic Times',
      logo: '/assets/news/publications/et.svg'
    }
  },
  {
    headline: 'B2B SaaS startup GenY Labs closes seed round led by BVR Mohan Reddy, others',
    date: 'Dec 12, 2019',
    category: 'Portfolio',
    uri: 'https://yourstory.com/2019/12/funding-b2b-saas-startup-geny-labs-seed-mohan-reddy',
    image: 'https://images.yourstory.com/cs/2/b87effd06a6611e9ad333f8a4777438f/Imageq5591576144111792jpg?fm=png&auto=format',
    summary: 'Hyderabad- and Toronto-based GenY Labs, which operates Auris, an AI platform that curates consumer insights in real time, has raised $700,000',
    publication: {
      name: 'YourStory',
      logo: '/assets/news/publications/yourstory.svg'
    }
  },
  {
    headline: 'Fintech startup Wealthy Bags $1.3M from Good Capital, Emphasis Ventures',
    date: 'Oct 23, 2019',
    category: 'Portfolio',
    uri: 'https://inc42.com/buzz/fintech-startup-wealthy-bags-1-3-mn-from-good-capital-emphasis-ventures/',
    image: 'https://i1.wp.com/cdn.inc42.com/wp-content/uploads/2019/10/Untitled-design-2019-10-23T183659.049.jpg?w=1360&ssl=1',
    summary: 'Wealth management startup, Wealthy, has raised $1.3M fund in a round led by early-stage venture capital firm Good Capital',
    publication: {
      name: 'Inc 42',
      logo: '/assets/news/publications/inc42.svg'
    }
  },
  {
    headline: 'Digi-Prex raises $5.5M seed round from Khosla Ventures, SV Angel & others',
    date: 'Sep 20, 2019',
    category: 'Portfolio',
    uri: 'https://techcrunch.com/2019/09/20/digi-prex-online-medicine-delivery-india/',
    image: 'https://techcrunch.com/wp-content/uploads/2019/09/GettyImages-142420587-1.jpg?w=1390&crop=1',
    summary: 'Digi-Prex, a Hyderabad-based e-pharmacy that serves people with chronic conditions, has raised $5.5M after recently graduating from Y Combinator',
    publication: {
      name: 'TechCrunch',
      logo: '/assets/news/publications/techcrunch.svg'
    }
  },
  {
    headline: 'Healthtech startup Doxper raises $4M',
    date: 'Aug 14, 2019',
    category: 'Portfolio',
    uri: 'https://yourstory.com/2019/08/startup-funding-healthtech-doxper-alkemi-venture-partners',
    image: 'https://images.yourstory.com/cs/2/b87effd0-6a66-11e9-ad33-3f8a4777438f/doxper1565757758607.jpg',
    summary: 'Mumbai-based Doxper, a data capture solution that allows doctors to instantly digitise case sheets, has raised $4 million',
    publication: {
      name: 'YourStory',
      logo: '/assets/news/publications/yourstory.svg'
    }
  },
  {
    headline: 'Learning should be fun for children',
    date: 'Apr 07, 2019',
    category: 'Portfolio',
    uri: 'https://www.telegraphindia.com/technology/learning-should-be-fun-for-children/cid/1688349',
    image: 'https://static.telegraphindia.com/derivative/THE_TELEGRAPH/1688349/16X9/imagef0e3328d-eb49-49d0-9a55-68afee6c7c8d.jpg',
    summary: 'The story of how Flintobox became a category leader in its segment',
    publication: {
      name: 'Telegraph',
      logo: '/assets/news/publications/telegraph.png'
    }
  },
  {
    headline: 'Zoomcar May Book Ticket For Unicorn Club With Potential Mahindra Funding',
    date: 'Apr 03, 2019',
    category: 'Portfolio',
    uri: 'https://inc42.com/buzz/zoomcar-may-book-ticket-for-unicorn-club-with-potential-mahindra-funding/',
    image: 'https://i0.wp.com/inc42.com/wp-content/uploads/2019/01/Zoomcar-feature.jpg?w=1360&ssl=1',
    summary: 'Bengaluru-based self-drive car rental platform, Zoomcar, is in talks to raise around $500 Mn in a funding round led by automaker Mahindra & Mahindra.',
    publication: {
      name: 'Inc 42',
      logo: '/assets/news/publications/inc42.svg'
    }
  },
  {
    headline: 'Why Meesho is in Globevestor\'s anti-portfolio',
    date: 'Mar 19, 2019',
    category: 'Thoughts',
    uri: 'https://www.livemint.com/companies/start-ups/why-meesho-is-in-globevestor-s-anti-portfolio-1552941543926.html',
    image: 'https://images.livemint.com/img/2019/03/18/600x338/PN-Raju_1552941798418.jpg',
    summary: "Some thoughts on our anti-portfolio and why Meesho is in it",
    publication: {
      name: 'LiveMint',
      logo: '/assets/news/publications/livemint.svg'
    }
  },
  {
    headline: 'Reverse Pitch for AI Startups: AI and Humans Working in Tandem',
    date: 'Feb 25, 2019',
    category: 'Thoughts',
    uri: 'https://www.livemint.com/companies/start-ups/reverse-pitch-for-ai-startups-ai-and-humans-working-in-tandem-1550612349924.html',
    image: 'https://images.livemint.com/img/2019/02/19/600x338/Ankur_1550612598115.jpg',
    summary: "Our thoughts on the next generation of enterprise and SaaS startups that we'd like to invest in",
    publication: {
      name: 'LiveMint',
      logo: '/assets/news/publications/livemint.svg'
    }
  },
  {
    headline: 'This Startup Wants To Wean Kids Off Smartphones With Engaging Activities',
    date: 'Nov 29, 2018',
    category: 'Portfolio',
    uri: 'https://www.forbes.com/sites/anuraghunathan/2018/11/29/this-startup-wants-to-wean-kids-off-smartphones-and-gadgets-with-engaging-activities/',
    image: 'https://thumbor.forbes.com/thumbor/960x0/https%3A%2F%2Fblogs-images.forbes.com%2Fanuraghunathan%2Ffiles%2F2018%2F11%2FFlintobox-1200x675.jpg',
    summary: 'Flinto is helping keep kids intellectually challenged and engaged without giving them smartphones and gadgets.',
    publication: {
      name: 'Forbes',
      logo: '/assets/news/publications/forbes.svg'
    }
  },
  {
    headline: 'AI Startup Actionable Science Raises $1 Mn Funding',
    date: 'Aug 02, 2018',
    category: 'Funding',
    uri: 'https://inc42.com/buzz/ai-startup-actionable-science-raises-1-mn-funding-from-globevestor-and-tri-valley/',
    image: 'https://i2.wp.com/inc42.com/wp-content/uploads/2018/08/feature-image-AI.jpg?w=1360&ssl=1',
    summary: 'Bengaluru-based AI startup Actionable Science has raised $1 Mn in a seed funding round from Globevestor.',
    publication: {
      name: 'Inc 42',
      logo: '/assets/news/publications/inc42.svg'
    }
  },
  {
    headline: 'Rooftop solar startup Oorjan raises $450K led by Globevestor',
    date: 'Oct 05, 2017',
    category: 'Funding',
    uri: 'https://www.vccircle.com/rooftop-solar-startup-oorjan-raises-450k-led-by-globevestor/',
    image: 'https://assets.vccircle.com/uploads/2017/07/solar.jpg',
    summary: 'Mumbai-based rooftop solar platform Oorjan has raised $450,000 in seed funding.',
    publication: {
      name: 'VC Circle',
      logo: '/assets/news/publications/vccircle.svg'
    }
  },
  {
    headline: 'Dell is using ink made from smog to print some of its packaging',
    date: 'Jul 24, 2018',
    category: 'Portfolio',
    uri: 'https://www.fastcompany.com/90206673/dell-is-using-ink-made-from-smog-to-print-some-of-its-packaging',
    image: 'https://images.fastcompany.net/image/upload/w_1153,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy/wp-cms/uploads/2018/07/p-1-90206673-this-packaging-is-printed-with-ink-made-from-indian-smog.jpg',
    summary: 'Dell has partnered with Chakr to use ink made from soot to print its packaging.',
    publication: {
      name: 'Fast Company',
      logo: '/assets/news/publications/fastcompany.svg'
    }
  },
  {
    headline: 'Zoomcar raises $3.6 M debt fund from Trifecta',
    date: 'Aug 03, 2018',
    category: 'Funding',
    uri: 'https://yourstory.com/2018/08/f-world-zoomcar-raises-3-6-m-debt-fund-trifecta/',
    image: 'https://images.yourstory.com/cs/wordpress/2017/11/Zoomcar.jpg',
    summary: 'Zoomcar has raised $3.6M from Trifecta Capital, following a $40M Series C earlier in the year.',
    publication: {
      name: 'YourStory',
      logo: '/assets/news/publications/yourstory.svg'
    }
  },
  {
    headline: 'Edtech startup Flinto raises Rs 6 cr in debt funding led by InnoVen Capital',
    date: 'Aug 01, 2018',
    category: 'Funding',
    uri: 'https://yourstory.com/2018/08/edtech-startup-flinto-raises-rs-6-cr-debt-funding-led-innoven-capital',
    image: 'https://images.yourstory.com/cs/wordpress/2018/08/Flinto-.jpg',
    summary: 'Chennai-based edtech startup Flinto has raised debt funding of Rs 6 crore led by InnoVen Capital. The funding will be used to expand the company\'s domestic presence.',
    publication: {
      name: 'YourStory',
      logo: '/assets/news/publications/yourstory.svg'
    }
  },
  {
    headline: 'The uncommon story of an edtech startup that caught elusive profitability by the tail',
    date: 'Oct 11, 2017',
    category: 'Portfolio',
    uri: 'https://www.techinasia.com/springboard-edtech-startup-profitable',
    image: 'https://cdn.techinasia.com/wp-content/uploads/2017/10/Springboard_team_Malaysia.jpg',
    summary: 'The story of how Springboard has found a sweet spot in a growing edtech space.',
    publication: {
      name: 'Tech in Asia',
      logo: '/assets/news/publications/techinasia.png'
    }
  },
  {
    headline: 'Globevestor to step up investments, float second micro fund',
    date: 'Sep 19, 2017',
    category: 'Profile',
    uri: 'https://www.vccircle.com/globevestor-to-step-up-investments-float-second-micro-fund/',
    image: 'https://assets.vccircle.com/uploads/2017/05/investment.jpg',
    summary: 'Globevestor plans to make 18 investments in a year compared with 12 currently and launch its second micro venture capital fund.',
    publication: {
      name: 'VC Circle',
      logo: '/assets/news/publications/vccircle.svg'
    }
  },
]