import React, { Component } from 'react';

import { Section, Slide, Content, Division, Heading, Row, Column, Paragraph } from '../elements/core';
import { commonStyles } from '../../styles/common';
import * as Icons from '../../icons/icons';

class ManifestoSection extends Component {

  render() {
    const { sectionCopy = {} } = this.props;
    return (
      <Section styles={{...commonStyles.section, ...customStyles.section}} data-role="manifesto">
        <Slide styles={customStyles.slide}>
          <Content styles={customStyles.content}>
            <Division styles={commonStyles.sectionTitle}>
              <Division styles={commonStyles.tagline}>{sectionCopy.tagline}</Division>
              <Heading styles={commonStyles.sectionHeading}>{sectionCopy.heading}</Heading>
            </Division>
            <Division styles={{...commonStyles.sectionDescription, ...customStyles.description}}>
              <Heading as="h5">{sectionCopy.description}</Heading>
            </Division>
            <Row styles={customStyles.investmentPrinciples}>
              <Column spans={{medium: 8}} styles={customStyles.principle}>
                <Icons.Stage styles={customStyles.icons} />
                <Heading as="h5" styles={customStyles.category}>{sectionCopy.principles.stage.title}</Heading>
                <Paragraph styles={customStyles.belief}>{sectionCopy.principles.stage.belief}</Paragraph>
              </Column>
              <Column spans={{medium: 8}} styles={customStyles.principle}>
                <Icons.Globe styles={customStyles.icons} />
                <Heading as="h5" styles={customStyles.category}>{sectionCopy.principles.geography.title}</Heading>
                <Paragraph styles={customStyles.belief}>{sectionCopy.principles.geography.belief}</Paragraph>
              </Column>
              <Column spans={{medium: 8}} styles={customStyles.principle}>
                <Icons.Thesis styles={customStyles.icons} />
                <Heading as="h5" styles={customStyles.category}>{sectionCopy.principles.thesis.title}</Heading>
                <Paragraph styles={customStyles.belief}>{sectionCopy.principles.thesis.belief}</Paragraph>
              </Column>
            </Row>
            <Division styles={customStyles.pointer}>
              <Icons.Playbook iconStyle={customStyles.pointerColor} styles={customStyles.pointerIcon} />
            </Division>
            <Division styles={customStyles.callToAction}>
              {sectionCopy.callToAction}
            </Division>
          </Content>
        </Slide>
        <Slide></Slide>
      </Section>
    );
  }
}

const customStyles = {
  section: {
    backgroundColor: '#41456a'
  },
  content: {
    color: 'white',
    textAlign: 'center',
    transform: `scale(${window.innerHeight > 800 ? 1 : (window.innerHeight/800)})`
  },
  description: {
    textAlign: 'left'
  },
  principle: {
    marginBottom: 0
  },
  icons: {
    height: '4rem',
    marginBottom: '1rem'
  },
  category: {
//    color: '#c1c494',
    color: 'white',
    opacity: 1,
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  belief: {
    fontSize: '1.1rem',
    fontWeight: 300,
    opacity: 0.7,
    textAlign: 'left'
  },
  pointer: {
    display: 'none',
    margin: '2rem 0',
    '@media (min-width: 768px)': {
      display: 'block'
    }
  },
  pointerColor: {
    primaryColor: '#c1c494'
  },
  pointerIcon: {
    height: 'auto',
    width: '75%',
    ...commonStyles.marginsCenter
  },
  callToAction: {
    fontSize: '1.3rem',
    fontWeight: 500,
    opacity: 1
  }
};

export default ManifestoSection;
