import React, { Component } from 'react';

import { 
  Division, 
  Heading,
  Span,
  Paragraph, 
  Anchor, 
  Image 
} from '../../elements/core';
//import { commonStyles } from '../../../styles/common';

class NewsSnippet   extends Component {

  render() {
    const news = this.props.news;
    return (
      <Division styles={customStyles.container}>
        <Anchor href={news.uri} target={'_blank'}>
          <Division styles={customStyles.newsItem}>
            <Division styles={{...customStyles.imageContainer, backgroundImage: `url(${news.image})`}} />
            <Division styles={customStyles.content}>
              <Span styles={customStyles.category}>{news.category}</Span>      
              <Heading as={'h6'} styles={customStyles.headline}>{news.headline}</Heading>
              <Span styles={customStyles.date}>{news.date}</Span>      
              <Paragraph styles={customStyles.summary}>{news.summary}</Paragraph>
            </Division>
            <Division styles={customStyles.footer}>
              {
                news.publication.logo
                ? <Image styles={customStyles.publicationLogo} src={news.publication.logo} />
                : news.publication.name
              }
            </Division>
          </Division>
        </Anchor>
      </Division>
    );
  }
}

const customStyles = {
  container: {
    height: 0.5 * window.innerHeight,
    padding: '1rem 0',
    '@media (max-width: 575px)': {
      height: 0.7 * window.innerHeight,
    }
  },
  newsItem: {
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    background: '#fff',
    minWidth: 0,
    borderRadius: '0.25rem',
    marginRight: '1rem',
    '&:hover': {  
      transform: 'translateY(-6px)',
      transition: 'all 0.2s ease-in-out'
    },
    '@media (max-width: 575px)': {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  imageContainer: {
    height: 120,
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: '50%'
  },
  content: {
    height: '80%',
    padding: 18,
    textDecoration: 'none',
    fontSize: '0.9rem',
  },
  category: {
    color: '#e33521',
    fontSize: '0.8rem',
    lineHeight: 1.1,
    fontWeight: '600',
    textTransform: 'uppercase',
    opacity: 0.6
  },
  headline: {
    fontWeight: 600,
    fontSize: '0.9rem',
    color: '#1e2022',
    marginBottom: '0.12rem',
    flexGrow: 0,
    flexShrink: 0
  },
  date: {
    color: '#8f8f8f',
    fontSize: '0.7rem',
    lineHeight: 1.1,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  summary: {
    marginTop: '0.5rem',
    color: '#666666',
    lineHeight: 1.4,
    fontSize: '0.9rem',
    flexGrow: 1,
    flexShrink: 1
  },
  footer: {
    padding: '18px 0',
    margin: '0 18px',
    borderTop: '1px solid #e7eaf3',
    fontWeight: '600',
    color: '#030303',
    flexGrow: 0,
    flexShrink: 0
  },
  publicationLogo: {
    height: 16
  }

};

export default NewsSnippet;