import React, { Component } from 'react';

import { Section, Slide, Content, Division, Row, Column, Span, Heading } from '../elements/core';
import { commonStyles } from '../../styles/common';
import * as Icons from '../../icons/icons';

class FooterSection extends Component {

  render() {
    const { sectionCopy = {}, contactDetails = {} } = this.props;
    return (
      <Section className="section" styles={{...commonStyles.section, ...customStyles.section}} data-role="footer">
        <Slide className="slide" styles={customStyles.slide}>
          <Content styles={customStyles.content}>
            <Division styles={commonStyles.sectionTitle}>
              <Division styles={commonStyles.tagline}>{sectionCopy.tagline}</Division>
              <Heading styles={commonStyles.sectionHeading}>{sectionCopy.heading}</Heading>
            </Division>
            <Division styles={commonStyles.sectionDescription}>
              <Heading as="h5">{sectionCopy.description}</Heading>
            </Division>
            <Row styles={customStyles.contactsContainer}>
              <Column spans={{medium: 8}} styles={customStyles.contact}>
                <Icons.Email styles={customStyles.icons} />
                {
                  contactDetails.email.map((email, index) => (
                    <Division key={index} styles={customStyles.contactDetail}>
                      <Division styles={customStyles.contactType}>{email.title}</Division>
                      <Division styles={customStyles.contactInfo}>{email.address}</Division>
                    </Division>
                  ))
                }                
              </Column>
              <Column spans={{medium: 8}} styles={customStyles.contact}>
                <Icons.Phone styles={customStyles.icons} />
                {
                  contactDetails.phone.map((phone, index) => (
                    <Division key={index} styles={customStyles.contactDetail}>
                      <Division styles={customStyles.contactType}>{phone.title}</Division>
                      <Division styles={customStyles.contactInfo}>{phone.number}</Division>
                    </Division>
                  ))
                }
              </Column>
              <Column spans={{medium: 8}} styles={customStyles.contact}>
                <Icons.Globe styles={customStyles.icons} />
                {
                  contactDetails.address.map((address, index) => (
                    <Division key={index} styles={customStyles.contactDetail}>
                      <Division styles={customStyles.contactType}>{address.title}</Division>
                      {
                        address.lines.map((line, index) => (
                          <Span key={index} styles={customStyles.contactInfoMultiline}>{line}</Span>
                        ))
                      }
                    </Division>
                  ))
                }
              </Column>
            </Row>
          </Content>
        </Slide>
      </Section>
    );
  }
}

const customStyles = {
  section: {
    backgroundImage: 'linear-gradient(150deg, #19a0ff 0%, #2d1582 100%)',
    backgroundRepeat: 'repeat-x'
  },
  content: {
    color: 'white',
    transform: `scale(${window.innerHeight > 800 ? 1 : (window.innerHeight/800)})`
  },
  contactsContainer: {
    marginTop: '4rem'
  },
  contact: {
    textAlign: 'center'
  },
  icons: {
    height: '6rem',
    marginBottom: '1rem',
  },
  contactType: {
    fontSize: '1.1rem',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  contactInfo: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
    fontSize: '1.2rem',
    opacity: 0.7
  },
  contactInfoMultiline: {
    display: 'block',
    margin: 0,
    fontSize: '1.2rem',
    opacity: 0.7
  }
};

export default FooterSection;
