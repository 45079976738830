import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Division, Anchor, Paragraph } from '../core';
import { commonStyles } from '../../../styles/common';

class FloatingImage extends Component {

  static defaultProps = {
    styles: {}
  }

  render() {
    const { image = '', styles : customImageStyles = {}, name = '',
            internalURI, externalURI, externalTarget} = this.props.imageData;
    const { onClick, styles : commonImageStyles } = this.props;

    const styles = {
      wrapper: {
        position: 'relative',
        ...commonImageStyles.wrapper,
        ...customImageStyles.wrapper
      },
      container: {
        position: 'absolute',
        overflow: 'hidden',
        transform: 'translate(-50%, -50%)',
        backgroundSize: 'cover',
        backgroundPosition: '50%',
        backgroundImage: `url('${image}')`,
        ...commonImageStyles.container,
        ...customImageStyles.container,
      },
      overlay: {
        ...commonStyles.fullWidthAbsolute,
        ...commonStyles.positionItemsMiddle,
        textAlign: 'center',
        flexDirection: 'column',
        ...commonImageStyles.overlay,
        ...customImageStyles.overlay
      },
      link: {
        ...commonImageStyles.link,
        ...customImageStyles.link
      },
      name: {
        ...commonImageStyles.name,
        ...customImageStyles.name
      }
    };

    let ClickElement = null;
    if (internalURI || externalURI || onClick) {
      if (onClick) {
        if (internalURI) {
          ClickElement = (props) =>
            <Link {...props} to={internalURI} onClick={onClick} />;
        } else if (externalURI) {
          ClickElement = (props) =>
            <Anchor {...props} href={externalURI} target={externalTarget} onClick={onClick} />;
        } else {
          ClickElement = (props) =>
            <Anchor {...props} onClick={onClick} />;
        }
      } else if (internalURI) {
        ClickElement = (props) =>
          <Link {...props} to={internalURI} />;
      } else {
        ClickElement = (props) =>
          <Anchor {...props} href={externalURI} target={externalTarget} />;
      }
    }

    const ImageElement = (props) => {
      return (
        <Division styles={styles.container}>
          <Division styles={styles.overlay}>
            { name ? <Paragraph>{name}</Paragraph> : null }
          </Division>
        </Division>
      )
    };

    return (
      <Division {...this.props} styles={styles.wrapper}>
        {
          ClickElement
          ? <ClickElement styles={styles.link}><ImageElement /></ClickElement>
          : <ImageElement />
        }
      </Division>
    );
  }
}

export default FloatingImage;
