import React, { Component } from 'react';

import { Section, Slide, Content, Division, Heading, Row, Column } from '../elements/core';
import { commonStyles } from '../../styles/common';

// Look at Classic Startup
class StatsSection extends Component {

  render() {
    const { sectionCopy = {} } = this.props;
    return (
      <Section className="section" styles={{...commonStyles.section, ...customStyles.section}} data-role="stats">
        <Slide className="slide" styles={customStyles.slide}>
          <Content styles={customStyles.content}>
            <Division styles={commonStyles.sectionTitle}>
              <Division styles={commonStyles.tagline}>{sectionCopy.tagline}</Division>
              <Heading styles={commonStyles.sectionHeading}>{sectionCopy.heading}</Heading>
            </Division>
            <Division styles={commonStyles.sectionDescription}>
              <Heading as="h5">{sectionCopy.description}</Heading>
            </Division>
            <Row styles={customStyles.statsContainer}>
            {
              this.props.fundMetrics.map((metric, index) => (
                <Column key={index} spans={{small: 12, medium: 8}} styles={customStyles.stat}>
                  <Division styles={customStyles.statTitle}>{metric.title}</Division>
                  <Division styles={customStyles.statNumber}>{metric.number}</Division>
                  <Division styles={customStyles.statDescription}>{metric.description}</Division>
                </Column>
              ))
            }
            </Row>
          </Content>
        </Slide>
      </Section>
    );
  }
}

const customStyles = {
  section: {
    backgroundColor: '#13354e'
  },
  content: {
    color: '#a5c8d1',
    transform: `scale(${window.innerHeight > 800 ? 1 : (window.innerHeight/800)})`
  },
  statsContainer: {
    marginTop: '4rem',
    justifyContent: 'center',
    textAlign: 'center'
  },
  stat: {
    display: 'flex',
    flexDirection: 'column'
  },
  statTitle: {
    fontSize: '1.1rem',
    textTransform: 'uppercase',
    fontWeight: 600,
    opacity: 0.9,
    flex: '0 0 15%'
  },
  statNumber: {
    color: 'white',
    fontSize: '4rem',
    textTransform: 'uppercase',
    fontWeight: 700,
    flex: '0 0 60%'
  },
  statDescription: {
    color: 'white',
    opacity: 0.6,
    flex: '0 0 25%',
    padding: '0 5%'
  },
};

export default StatsSection;
