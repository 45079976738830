import React, { Component } from 'react';
import ReactGA from 'react-ga';

import { Column, Division, Image } from '../../elements/core';

class PortfolioCard extends Component {

  render() {
    const company = this.props.company;
    return (
        <Column spans={{default: 12, small: 8, medium: 6, large: 4}} styles={customStyles.container}>
          <ReactGA.OutboundLink 
            eventLabel={`Portfolio Company - ${company.name}`} 
            to={company.uri} 
            target={'_blank'}
          >
            <Division styles={customStyles.body}>
              <Division styles={customStyles.logoContainer}>
                <Image src={company.logo} styles={customStyles.logo} alt={company.name} />
              </Division>
            </Division>
          </ReactGA.OutboundLink>
        </Column>
    );
  }
}

const customStyles = {
  container: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    backgroundClip: 'border-box',
    border: '2px solid #e7eaf3',
    marginBottom: -2,
    marginRight: -2,
    padding: 4,
    background: '#fff',
    '@media (min-width: 576px)': {
    }
  },
  body: {
    flex: '1 1 auto',
    padding: '0rem'
  },
  logoContainer: {
    height: 72,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  cohortNumber: {
    position: 'absolute',
    top: 4,
    left: 12,
    color: '#13354e',
    opacity: 0.2,
    fontWeight: 700,
    fontSize: '1.2rem'
  },
  status: {
    position: 'absolute',
    bottom: 0,
    right: 8,
    color: 'green',
    opacity: 0.4,
    fontWeight: 700,
    fontSize: '1.1rem',
    textTransform: 'uppercase'
  }
};

export default PortfolioCard;
