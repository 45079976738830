import React, { Component } from 'react';
import { Button, Span } from '../core';
import chroma from 'chroma-js/chroma';

const a = chroma('#377dff').darken().hex();

class PillButton extends Component {

  static defaultProps = {
    styles: {
    }
  }

  render() {
    const styles = {
      container: {
        display: 'inline-flex',
        cursor: 'pointer',
        alignItems: 'center',
        backgroundColor: a,
        color: 'white',
        border: 0,
        boxShadow: '0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125)',
        borderRadius: '6.1875rem',
        padding: '0.75rem 1rem',
        margin: '1rem 0',
        fontWeight: 400,
        '&:hover': {
          background: '#377dff'
        }
      },
      category: {
        display: 'inline-block',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        userSelect: 'none',
        border: '1px solid transparent',
        padding: '0.4375rem 0.9375rem',
        fontSize: '0.75rem',
        lineHeight: 1.5,
        borderRadius: '6.1875rem',
        transition: 'all 0.2s ease-in-out',
        textTransform: 'uppercase',
        background: 'rgba(255, 255, 255, 0.1)',
        marginRight: '1rem',
        '@media (max-width: 575px)': {
          fontSize: '0.8rem'
        },
      },
      callToAction: {
        display: 'block',
        '@media (max-width: 575px)': {
          fontSize: '0.9rem'
        },
        '@media (min-width: 576px)': {
          display: 'inline-block'
        }
      },
      arrow: {
        marginLeft: '0.5rem',
    		maxWidth: 20,
        width: '100%',
        height: 'auto',
        maxHeight: 18,
        fill: 'white'
      },
      ...this.props.styles
    };

    return (
      <Button {...this.props} styles={styles.container}>
        {
          this.props.category
          ? <Span styles={styles.category}>{this.props.category}</Span>
          : null
        }
        <Span styles={styles.callToAction}>
          {this.props.callToAction}
        </Span>
        <Span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={styles.arrow}>
            <path d="M12.5 45.83h64.58v8.33H12.5z"/>
            <path d="M59.17 77.92l-5.84-5.84L75.43 50l-22.1-22.08 5.84-5.84L87.07 50z"/>
          </svg>
        </Span>
      </Button>
    );
  }
}

export default PillButton;

/*
<a onClick={this.moveDown}
  className="d-sm-inline-flex align-items-center bg-primary text-white shadow rounded-pill p-2 pr-3 mb-3 mt-3" href="#">
  <span className="btn btn-xs btn-soft-white btn-pill font-weight-semi-bold mr-3">PORTFOLIO</span>
  <span className="d-block d-sm-inline-block">
    Read Our Founder Stories &raquo;
  </span>
</a>
*/
