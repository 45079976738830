import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Typed from 'react-typed';
import ReactGA from 'react-ga';

import { Section, Slide, Content, Header, Division, Span } from '../elements/core';
import { ScrollerMouse, PillButton } from '../elements/common';
import { commonStyles } from '../../styles/common';
import { themes } from '../../styles/theme';
import { Logo } from '../../icons/icons';
//import NavbarMenu from './Menu';

import { renderSmartHTML } from './utils/smartHTML';

class CoverSection extends Component {

  moveDown = (eventTrigger) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Scrolled Down',
      label: eventTrigger
    });
    this.props.fullpageAPI.moveSectionDown();
  };

  clickedButton = () => {
    this.moveDown('Clicked Button');
  };

  clickedMouse = () => {
    this.moveDown('Clicked Mouse');
  };

  render() {
    const { sectionCopy = {} } = this.props;
    return (
      <Section styles={customStyles.section} data-role="cover">
        <Slide styles={customStyles.slide}>
        <Content styles={customStyles.content}>
            <Header styles={customStyles.header}>
              <Link to="/">
                <Logo styles={customStyles.logo} />
              </Link>
            </Header>
            <Division styles={customStyles.pitch}>
              <Division styles={commonStyles.tagline}>{sectionCopy.tagline}</Division>
              <Division styles={customStyles.partnership}>
                {sectionCopy.heading}
                <Division styles={customStyles.goal}>
                  <Typed
                    strings={sectionCopy.goals}
                    typeSpeed={60}
                    backSpeed={50}
                    loop
                  />
                </Division>
              </Division>
              <Division styles={customStyles.description}>
                {
                  renderSmartHTML(sectionCopy.description, { Link, Span })
                }
              </Division>
              {
              this.props.fullpageAPI ?
                <PillButton 
                  as={'a'} 
                  onClick={this.clickedButton} 
                  category={sectionCopy.button.category} 
                  callToAction={sectionCopy.button.callToAction} 
                />
              : null
            }
            </Division>
            <ScrollerMouse onClick={this.clickedMouse} />
          </Content>
        </Slide>
      </Section>
    );
  }
}

const customStyles = {
  section: {
    backgroundImage: 'linear-gradient(150deg, #2d1582 0%, #19a0ff 100%)',
    backgroundRepeat: 'repeat-x'
  },
  slide: {
    backgroundImage: 'url(/assets/globe.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right'
  },
  header: {
    '@media (max-width: 575px)': {
      paddingTop: '0.5rem'
    },
    '@media (min-width: 576px)': {
      paddingTop: '2rem'
    },
  },
  logo: {
    color: 'white',
    height: '3rem'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
    '@media (max-width: 575px)': {
      paddingBottom: '4rem',
      paddingTop: '12.5rem',  
    },
    transform: `scale(${window.innerHeight > 800 ? 1 : (window.innerHeight/800)})`
  },
  pitch: {
    marginBottom: '0rem',
    color: '#fff',
    '@media (min-width: 992px)': {
      width: '60%',
      marginTop: '4rem'
    },
    '@media (min-width: 1200px)': {
      width: '50%'
    }
  },
  partnership: {
    fontWeight: 500,
    fontSize: '2rem',
    lineHeight: 1.5,
    marginBottom: '0.5rem',
    '@media (max-width: 575px)': {
      fontSize: '1.8rem'
    }
  },
  goal: {
    marginTop: '0.25rem',
    color: themes.primary.primaryColor,
    fontWeight: 600,
    '@media (max-width: 575px)': {
      minHeight: 100
    }
  },
  description: {
    fontSize: '1.2rem',
    fontWeight: 300,
    lineHeight: 1.5,
    marginBottom: '1rem'
  }
};

export default CoverSection;