import React from 'react';

const defaultRenameAttributes = {
  'class': 'className'
};
const cleanWhiteSpaces = (text) => text.replace(/[\s ]{2,}/g, ' ').replace(/(^\s+|\s+$)/g, '');
const cleanExtraQuotes = (text) => text.replace(/(^["']+|["']+$)/g, '');

const cleanRawAttribute = (rawAttribute, styleRefs, eventRefs, renameAttributes) => {
  // If HTML attribute does not have a value, set it to true.
  let [ attrType, attrValue = 'true' ] = rawAttribute.split('=');
  console.log(attrType, renameAttributes);
  attrType = renameAttributes[attrType] ? renameAttributes[attrType] : attrType;
  attrValue = cleanExtraQuotes(attrValue);
  return { attrType, attrValue };
};

export const renderSmartHTML = (text, Components = {}, styleRefs = {}, eventRefs = {}, renameAttributes = defaultRenameAttributes) => {
  // Remove extra whitespaces, newline characters and leading & trailing whitespaces
  const globalRegEx = new RegExp('(<.*?>.*?</.*?>)', 'g');
  const tagRegEx = new RegExp('<(.*?)>', 'g');
  const componentArray = cleanWhiteSpaces(text).split(globalRegEx).filter((e) => e !== "").map((element, index) => {
    let Tag = Components.Span || 'span', innerHTML = element, tagAttributes = {};
    try {
      if (globalRegEx.test(element)) {
        const [ tag, tagContent ] = element.split(tagRegEx).filter((e) => e !== "");
        innerHTML = tagContent;
        const [ tagType, ...tagRawAttributes] = tag.split(' ');
        tagAttributes = tagRawAttributes.reduce((obj, rawAttribute) => {
          const { attrType, attrValue } = cleanRawAttribute(rawAttribute, styleRefs, eventRefs, renameAttributes);
          obj[attrType] = attrValue;
          return obj;         
        }, {});
        switch(tagType) {
          case 'link':
            if (tagAttributes['to']) { Tag = Components.Link;}
            break;
          default:
            break;
        }
      }
    } catch(err) { console.log(err) }
    return <Tag key={index} {...tagAttributes}>{innerHTML}</Tag>;
  });
  return componentArray;
};