import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { themes } from '../../styles/theme';

class Globe extends Component {

  static propTypes = {
    title: PropTypes.string,
    iconStyle: PropTypes.object
  }

  static defaultProps = {
    title: 'Globe',
    iconStyle: {}
  }

  render() {

    const primaryColor = this.props.iconStyle.primaryColor
                          || themes.primary.primaryColor;
    const secondaryColor = this.props.iconStyle.secondaryColor
                          || themes.primary.primaryLightColor;

    const commonStyle = {
      strokeWidth: 3,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
      fill: 'none',
    };

    const Path = styled.path(commonStyle, props => ({...props.styles}));
    const Line = styled.line(commonStyle, props => ({...props.styles}));
    const Circle = styled.circle(commonStyle, props => ({...props.styles}));
    const SVG = styled.svg(commonStyle, props => ({...props.styles}));

    return (
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        className={this.props.className}
        aria-labelledby="title"
     	  viewBox="0 0 120 120"
        styles={this.props.styles}
      >
        <title>{this.props.title}</title>
        <g name="top-shadow">
          <Path d="M41.5,6.8c2.1-0.6,4.3-1.1,6.5-1.5"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.topShadow}}
          />
          <Path d="M16.2,22.2c3.8-3.9,8.1-7.3,12.8-10.1"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.topShadow}}
          />
          <Path d="M8.3,32.7c0.8-1.4,1.7-2.8,2.6-4.1"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.topShadow}}
          />
        </g>
        <g name="bottom-shadow">
          <Path d="M104.7,93.2c-2,3-4.4,5.8-6.9,8.4"
            styles={{stroke: primaryColor, ...this.props.iconStyle.bottomShadow}}
          />
          <Path d="M57.4,118.4c-9.8,0-19.1-2.5-27.1-6.9"
            styles={{stroke: primaryColor, ...this.props.iconStyle.bottomShadow}}
          />
          <Path d="M82.4,112.6c-1.9,0.9-3.8,1.7-5.8,2.5"
            styles={{stroke: primaryColor, ...this.props.iconStyle.bottomShadow}}
          />
        </g>
        <g name="globe-latitudes">
         	<Line x1="44.6" y1="46.9" x2="76.7" y2="46.9"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.globeLatitudes}}
          />
         	<Line x1="14.1" y1="46.9" x2="37.3" y2="46.9"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.globeLatitudes}}
          />
         	<Line x1="14.1" y1="76" x2="74.9" y2="76"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.globeLatitudes}}
          />
         	<Line x1="90.2" y1="76" x2="100.8" y2="76"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.globeLatitudes}}
          />
        </g>
        <g name="globe-longitudes">
          <Line x1="57.4" y1="15.4" x2="57.4" y2="107.5"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.globeLongitudes}}
          />
          <Path d="M71,17.4c-4.3-1.3-8.8-2-13.6-2C32,15.4,11.3,36,11.3,61.4c0,25.5,20.6,46.1,46.1,46.1
          	c25.5,0,46.1-20.6,46.1-46.1c0-5.3-0.9-10.4-2.6-15.2"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.globeLongitudes}}
          />
          <Path d="M70.4,21.6c-1.3-1.4-2.8-2.6-4.3-3.5c-2.7-1.7-5.7-2.7-8.7-2.7c-14.3,0-25.9,20.6-25.9,46.1
          	c0,25.5,11.6,46.1,25.9,46.1c14.3,0,25.9-20.6,25.9-46.1c0-4.7-0.4-9.3-1.2-13.6"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.globeLongitudes}}
          />
        </g>
        <g name="globe-marker">
          <Path d="M97.2,9.3c-11.5,0-20.7,9.3-20.7,20.7c0,6.1,7.2,19.7,19,36c0.4,0.6,1,0.8,1.7,0.8c0.7,0,1.3-0.3,1.7-0.8
            c11.8-16.1,19-29.7,19-36C117.9,18.6,108.6,9.3,97.2,9.3z"
            styles={{fill: secondaryColor, opacity: 0.2, ...this.props.iconStyle.globeMarkerShadow}}
          />
          <Path d="M91.1,1.6c-11.5,0-20.7,9.3-20.7,20.7c0,6.1,7.2,19.7,19,36c0.4,0.6,1,0.8,1.7,0.8c0.7,0,1.3-0.3,1.7-0.8
          	c11.8-16.1,19-29.7,19-36C111.7,11,102.4,1.6,91.1,1.6z"
            styles={{fill: primaryColor, ...this.props.iconStyle.globeMarker}}
          />
          <Circle cx="91.1" cy="22.4" r="9.6"
            styles={{stroke: secondaryColor, ...this.props.iconStyle.globeMarkerCircle}}
          />
        </g>
     </SVG>
   )
  }
}

export default Globe;
