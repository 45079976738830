export const founderStories = {
  featureImages: [
    '/assets/portfolio/zoomcar.jpg',
    '/assets/portfolio/flintobox.jpg',
    '/assets/portfolio/springboard.jpg',
    '/assets/portfolio/instaoffice.jpg',
    '/assets/portfolio/wealthy.jpg',
    '/assets/portfolio/doxper.jpg',
    '/assets/portfolio/oorjan.jpg',
    '/assets/portfolio/chakr.jpg'
  ],
  stories: [
  ]
};
